import React from 'react';
import cn from 'classnames';
import { Element } from 'react-scroll';
import Gallery from './Gallery';
import css from './Card.module.scss';

const Card = (props: { title: string; text: string; id: string; items: Array<{ pic: string; link: string }> }) => (
  <Element name={props.id} className={css.card}>
    <div className={cn(css.cardInner, 'grid', 'grid-cols-3')}>
      <div className={css.desc}>
        <h5 className="text-4 font-semibold">{props.title}</h5>
        <p className="text-1">{props.text}</p>
      </div>
      <Gallery items={props.items} />
    </div>
  </Element>
);

export default Card;
