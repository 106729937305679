import { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';

type Props = {
  children: ReactNode;
  to: string;
  props?: any;
  onClick?: () => void;
  className?: string;
};

export const LinkWithQuery: React.FC<Props> = ({ children, to, onClick, className = '', ...props }) => {
  const { search } = useLocation();

  return (
    <Link to={to + search} onClick={onClick} className={cn(className)} {...props}>
      {children}
    </Link>
  );
};
