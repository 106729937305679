import images from "assets/images";
import React from "react";
import styles from "./styles.module.scss";
import translator from "utils/translator";
import PageLayout from "components/common/PageLayout";

function Flow() {
  const _t = (path: string) => translator.text(`flow.${path}`);
  return (
    <PageLayout>
      <div className={`${styles.flowPage} flex flex-col items-center `}>
        <h1 className={`${styles.title} font-semibold text-center`}>
          {_t("title")}
        </h1>
        <img className={`${styles.mainImage} `} src={images.flowImage} alt="" />
        <div className={`${styles.content} flex flex-col lg:flex-row  `}>
          <div className={`flex-1`}>
            <h2
              className={`${styles.subTitle} flex-1 font-semibold text-center lg:text-left whitespace-wrap`}
            >
              {_t("subtitle")}
            </h2>
          </div>
          <div className={`flex-1`}>
            <p className={`${styles.description} flex-1 whitespace-pre-wrap `}>
              {_t("description")}
            </p>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default Flow;
