import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { gsap } from 'gsap';
import cn from 'classnames';
import { logicItems, text } from '../data';
import css from './CardsLogic.module.scss';

const CardsLogic = () => {
  const [activeId, setActiveId] = useState(-1);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
  const picRefs = useRef<(HTMLDivElement | null)[]>([]);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  useEffect(() => {
    const pic = picRefs.current[activeId];
    const item = itemRefs.current[activeId];

    if (!pic || !item) return;

    const initialHeight = item.clientHeight;
    const contentHeight = item.querySelector('[data-content="accordion"]')!.clientHeight;

    gsap.to(item, { height: initialHeight + contentHeight, duration: 0.1, ease: 'power4.in' });

    gsap.fromTo(
      pic,
      { alpha: 0, scale: 0.8 },
      {
        onStart: () => {
          pic.style.display = 'block';
        },
        alpha: 1,
        scale: 1,
        duration: 0.2,
        delay: 0.05,
        ease: 'power1.out',
      },
    );
    return () => {
      gsap.to(item, {
        height: initialHeight,
        duration: 0.05,
        ease: 'power4.out',
        onComplete: () => {
          item.style.height = '';
        },
      });

      gsap.to(pic, {
        ease: 'power2.inOut',
        duration: 0.15,
        alpha: 0,
        scale: 1.1,
        onComplete: () => {
          pic.style.display = '';
        },
      });
    };
  }, [activeId]);

  const setDefaultActive = (index: number) => {
    if (index === 0 && activeId === -1) {
      setActiveId(index);
    }
  };

  return (
    <div className={css.logic}>
      <h2 className={cn(css.title, 'text-5', 'font-semibold')}>{text('heroInfo2.title')}</h2>

      <div className={cn(css.content, 'grid', 'grid-cols-3')}>
        <div className={css.menu}>
          {logicItems.map((item, index) => (
            <div
              ref={(ref) => {
                itemRefs.current[index] = ref;
              }}
              onClick={() => setActiveId(index)}
              className={cn(css.item, 'text-2', { [css.active]: activeId === index })}
              key={`logic-menu-item-${item.title}`}
            >
              <h4 className="text-2 font-semibold">{item.title}</h4>
              <div data-content="accordion">
                <p className="text-1">{item.text}</p>
                {isMobile && <img src={item.pic} onLoad={() => setDefaultActive(index)} alt="" />}
              </div>
            </div>
          ))}
        </div>
        <div className={cn(css.images, 'col-span-2')}>
          {logicItems.map((item, index) => (
            <img
              src={item.pic}
              ref={(ref) => {
                picRefs.current[index] = ref;
              }}
              onLoad={() => setDefaultActive(index)}
              className={cn(css.pic, { [css.active]: activeId === index })}
              key={`logic-pic-item-${item.title}`}
              alt=""
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardsLogic;
