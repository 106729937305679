import React, { useEffect } from 'react';
import cn from 'classnames';
import translator from 'utils/translator';
import { LazyVideo } from 'components/common/LazyVideo';
import useResponsiveQuery from 'utils/useResponsiveQuery';
import Animation from './animation';
import css from './styles.module.scss';

type DataType = {
  title: string;
  desc: string;
  videoSrc?: string;
  imgSrc?: string;
  poster?: string;
};

type Props = { sectionId: 'create' | 'personalize' | 'ai'; reverse?: boolean };

const ScrollSection: React.FC<Props> = ({ reverse = false, sectionId }) => {
  const [activeIndex, setActiveIndex] = React.useState(-1);
  const isMobile = useResponsiveQuery();
  const containerRef = React.useRef<HTMLElement | null>(null);
  const tabsContentRef = React.useRef<HTMLDivElement>(null);
  const data = (translator.text(`homePageNew.${sectionId}.items`) ?? []) as DataType[];

  useEffect(() => {
    let animation: Animation | null = null;
    if (containerRef) animation = new Animation({ containerRef, onChange: setActiveIndex, isMobile });
    return () => {
      if (animation) animation.destroy();
    };
  }, [isMobile]);

  const renderItemContent = (item: typeof data[number], index: number) => (
    <div className={css.itemContent}>
      {'videoSrc' in item && (
        <LazyVideo src={item.videoSrc!} autoplay={index === activeIndex} poster={item.poster} once />
      )}
      {'imgSrc' in item && <img src={item.imgSrc} alt="demonstation content" loading="lazy" />}
    </div>
  );

  const getActiveClass = (index: number) => (index === activeIndex ? css.active : '');

  return (
    <section className={cn(css.container, reverse && css.reverse)} ref={containerRef}>
      <div className={cn(css.tabs, 'grid')}>
        <div className={cn(css.tabsNav, 'relative flex flex-col')}>
          {data.map((item, index) => (
            <div
              className={cn(css.item, 'flex items-center', isMobile ? 'flex-col' : '', getActiveClass(index))}
              key={`tab-head-${item.title}`}
              data-index={index}
            >
              {isMobile && renderItemContent(item, index)}
              <div>
                <h3 className={cn(css.itemTitle, 'font-semibold', 'text-left')}>{item.title}</h3>
                <div className={cn(css.itemDesc, 'overflow-hidden')}>
                  <p>{item.desc}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        {isMobile ? null : (
          <div className={cn(css.tabsContent, 'z-30 grid h-screen')} ref={tabsContentRef}>
            {data.map((item, index) => (
              <div
                className={cn(css.item, getActiveClass(index), 'h-screen w-full flex items-center')}
                key={`tab-content-${item.title}`}
                data-index={index}
              >
                {renderItemContent(item, index)}
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default ScrollSection;
