import React from 'react';
// import useResponsiveQuery from "utils/useResponsiveQuery";
import tr from 'utils/translator';
import PageLayout from 'components/common/PageLayout';
import InfoBox from 'components/common/InfoBox';
import imageDesktop from 'assets/images/analytics/analytics-image.png';
import styles from './analytics.module.scss';

function Analytics() {
  // const isMobile = useResponsiveQuery();
  // const bg = isMobile ? imageMobile : imageDesktop;
  const infoTexts = tr.text('analytics.infoSection');

  return (
    <PageLayout>
      <div className={styles.analyticsContent}>
        <div className={styles.topSection}>
          <h1 className={'font-semibold'}>{tr.text('analytics.mainTitle')}</h1>
          <img
            src={imageDesktop}
            alt="Storycards analytics"
            className={styles.analyticsImage}
          />
          <div className={styles.underImageTexts}>
            <h3 className={'font-semibold'}>{tr.text('analytics.subTitle')}</h3>
            <p>{tr.text('analytics.subTitleDescription')}</p>
          </div>
        </div>
        <div className={styles.infoSection}>
          {infoTexts.map((info: { title: string; text: string }) => {
            return (
              <InfoBox
                className={styles.infoBox}
                title={info.title}
                text={info.text}
                key={info.title}
              />
            );
          })}
        </div>
      </div>
    </PageLayout>
  );
}

export default Analytics;
