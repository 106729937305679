import React, { useEffect } from 'react';

import translator from 'utils/translator';
import Animation from './animation';
import styles from './styles.module.scss';

const _t = (path: string) => translator.text(`scrollingSection.${path}`);

const videoURL =
  'https://player.vimeo.com/external/565231410.hd.mp4?s=b4368815ebb21887d21c548046011610c2653305&profile_id=175';

function ScrollingSection({ innerRef }: any) {
  useEffect(() => {
    const animation = Animation.getInstance() || new Animation();
    return () => {
      animation.destroy();
    };
  }, []);

  return (
    <section className={`${styles.scrollingSection}`} ref={innerRef}>
      <div className={`${styles.header}`}>
        <h2 className={`${styles.title} text-white text-center font-semibold`}>{_t('title')}</h2>
        <p className={`${styles.subtitle} text-white text-center mx-auto`}>{_t('subtitle')}</p>
      </div>
      <div className={`${styles.fixedContent} bg-top bg-no-repeat bg-contain`}>
        <div className={`${styles.bgVideo}`}>
          <video src={videoURL} muted />
        </div>
        <div className={`${styles.scrollingTextWrap}`}>
          <div className={`${styles.scrollingTextInner}`}>
            {(_t('items') as Array<{ title: string; text: string }>).map((item, index) => (
              <div className={`${styles.scrollingText} text-white`} key={index}>
                <h3 className={`font-semibold`}>{item.title}</h3>
                <p>{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ScrollingSection;
