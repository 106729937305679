import * as React from "react";

function ChevronIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={10.924}
      viewBox="0 0 18 10.924"
      fill={props.color}
      {...props}
    >
      <path
        data-name="Icon awesome-chevron-down"
        d="M8.303 10.634L.29 2.623a.989.989 0 010-1.4l.934-.934a.989.989 0 011.4 0l6.379 6.348L15.382.288a.989.989 0 011.4 0l.934.934a.989.989 0 010 1.4l-8.013 8.012a.989.989 0 01-1.4 0z"
      />
    </svg>
  );
}

export default ChevronIcon;
