import React from 'react';
import useResponsiveQuery from 'utils/useResponsiveQuery';
import images from 'assets/images';
import { infoImg1, infoImg2, infoImg3, infoImg4, infoImg5 } from 'assets/images/mobile';
import styles from './styles.module.scss';

function InfoSection() {
  const isMobile = useResponsiveQuery();
  const mainTitleContent = 'Build multiple flows and create a unique user experience';
  const mainTextContent =
    "Create multiple flows and unique experiences according to the users' choices in each card. Storycards lets you show different content to every user, differentiating by their choices and engagement with the product.";
  return (
    <section className={`${styles.infoSection} w-full relative text-center md:text-left`}>
      <h2 className={`${styles.mainTitle} font-semibold`}>{mainTitleContent}</h2>
      <p className={`${styles.mainText} `}>{mainTextContent}</p>
      <img
        className={`${styles.mainImage} absolute`}
        src={isMobile ? infoImg1 : images.info1Image}
        alt="Flows - Storycards"
      />
      <div className={`${styles.infoItems} flex flex-col md:flex-row md:flex-wrap md:justify-between`}>
        {infos.map((item) => (
          <div className={`${styles.info} ${styles[item.className]}`} key={item.className}>
            <img
              className={`${styles.infoImage} mx-auto md:mx-0`}
              src={isMobile ? item.imageMobile : item.image}
              alt={item.title}
            />
            <div className={`${styles.description}`}>
              <h3 className={`${styles.infoTitle} font-semibold`}>{item.title}</h3>
              <p className={`${styles.infoText} `}>{item.text}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default InfoSection;

const infos = [
  {
    image: images.info2Image,
    imageMobile: infoImg2,
    title: 'Work with your team',
    text: 'Drive collaboration and create your own organization and groups with advanced roles and permissions.',
    className: 'join',
  },
  {
    image: images.info3Image,
    imageMobile: infoImg3,
    title: 'Publish and Scale',
    text: 'Go live with one click, and without any worries.  Storycards hosting network will scale your product by the number of visitors to your site and with 99.9% uptime.',
    className: 'publish',
  },
  {
    image: images.info4Image,
    imageMobile: infoImg4,
    title: 'Improve your products with Storycards insights',
    text: 'Storycards analyzes the user data and generates recommendations for Editors to make improvements and increase engagement that reaches your goals.',
    className: 'insights',
  },
  {
    image: images.info5Image,
    imageMobile: infoImg5,
    title: 'Export users data',
    text: "Easily download all users' data for each story you’ve created, including leads, and the answers users chose on your story. Export user data to any 3rd party CRM.",
    className: 'exportData',
  },
];
