import React from 'react';
import cn from 'classnames';
import useResponsiveQuery from 'utils/useResponsiveQuery';
import translator from 'utils/translator';

import PageLayout from 'components/common/PageLayout';
import { LinkWithQuery } from 'components/common/LinkWithQuery';

import images from 'assets/images';

import { PRICING_ROUTE } from 'components/constants';

import css from './AI.module.scss';

type Props = {};

const _t = (path: string) => translator.text(`ai.${path}`);

const sectionsInfo = [
  {
    title: _t('sections.section1.title'),
    text: _t('sections.section1.text'),
    video:
      'https://player.vimeo.com/progressive_redirect/playback/912106307/rendition/720p/file.mp4?loc=external&log_user=0&signature=159f6daf5a1fbf9c9c30ac3721a40a0f486d92bbf0eb13c10e94b2e0bb7e112b',
  },
  {
    title: _t('sections.section2.title'),
    text: _t('sections.section2.text'),
    video:
      'https://player.vimeo.com/progressive_redirect/playback/912108019/rendition/720p/file.mp4?loc=external&log_user=0&signature=0e6257d6f31dae279f5c69ddcba5ecf33fffe49d55e97936984893ceb32a361a',
  },
  {
    title: _t('sections.section3.title'),
    text: _t('sections.section3.text'),
    video:
      'https://player.vimeo.com/progressive_redirect/playback/912106329/rendition/720p/file.mp4?loc=external&log_user=0&signature=854c7a80f1452fe55a6d39e7da9f9dd4d1cbe9e730aac6567ea21c71821c108d',
  },
  {
    title: _t('sections.section4.title'),
    text: _t('sections.section4.text'),
    video:
      'https://player.vimeo.com/progressive_redirect/playback/912108327/rendition/720p/file.mp4?loc=external&log_user=0&signature=73153747d24f63e610db99527da12dcf02873f37e018e8ab0ec331aec6999214',
  },
];

const AI: React.FC<Props> = () => {
  const isMobile = useResponsiveQuery();
  const CTALabel = 'Start Creating';

  // useEffect(() => {
  //   const videos = document.querySelectorAll('video');
  //   const config = {
  //     threshold: 0,
  //   };

  //   const observer = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (!entry.isIntersecting) {
  //         (entry.target as HTMLVideoElement).pause();
  //         console.log('Exit video');
  //       } else {
  //         (entry.target as HTMLVideoElement).play();
  //         console.log('Enter video');
  //       }
  //     });
  //   }, config);

  //   videos.forEach((element) => {
  //     observer.observe(element);
  //   });

  //   return () => {
  //     videos.forEach((element) => {
  //       observer.unobserve(element);
  //     });
  //   };
  // }, []);
  const handleClickScrollToBtn = () => {
    const commonInfoDiv = document.getElementById('#common');
    if (commonInfoDiv) {
      const topPos = commonInfoDiv.offsetTop;
      window.scrollTo({ top: topPos, behavior: 'smooth' });
    }
  };

  return (
    <PageLayout className={css.ai} isFullWidth>
      <section
        className={css.topSection}
        style={{ backgroundImage: `url(${isMobile ? images.aiTopBgMob : images.aiTopBgDesk})` }}
      >
        <div className={css.info}>
          <h1>{_t('topSection.title')}</h1>
          <p className="text-1" data-name="test">
            {isMobile ? _t('topSection.subTitleMob') : _t('topSection.subTitle')}
          </p>
          <LinkWithQuery to={PRICING_ROUTE} className={css.CTAButton}>
            {CTALabel}
          </LinkWithQuery>
        </div>
        <div className={css.scrollBtn} onClick={handleClickScrollToBtn}>
          <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="48" height="48" rx="24" stroke="white" stroke-opacity="0.3" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18.88 22L25 28.1808L31.12 22L33 23.9028L25 32L17 23.9028L18.88 22Z"
              fill="white"
            />
          </svg>
        </div>
      </section>
      <div className={css.commonInfo} id="#common">
        <div className={css.firstSection}>
          <div
            className={cn(css.text, 'text-2')}
            dangerouslySetInnerHTML={{ __html: _t('sections.sectionTop.title') }}
          ></div>
          <div className={css.videoWrapper}>
            <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
              <iframe
                src="https://player.vimeo.com/video/911942395?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                title="Storycards AI"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>
        </div>
        <div className={css.sections}>
          {sectionsInfo.map((section, index) => {
            if (index === 0 || index % 2 === 0) {
              return (
                <div className={cn(css.section, css.even)} key={index}>
                  <div className={css.info}>
                    <h5 className="text-4 font-semibold">{section.title}</h5>
                    <div className="text-1" dangerouslySetInnerHTML={{ __html: section.text }}></div>
                  </div>
                  <div className={cn(css.videoWrapper, css.right)}>
                    <video src={section.video} loop muted autoPlay playsInline></video>
                  </div>
                </div>
              );
            } else {
              return (
                <div className={cn(css.section, css.odd)} key={index}>
                  <div className={cn(css.videoWrapper, css.left)}>
                    <video src={section.video} loop muted autoPlay playsInline></video>
                  </div>
                  <div className={css.info}>
                    <h5 className="text-4 font-semibold">{section.title}</h5>
                    <div className="text-1" dangerouslySetInnerHTML={{ __html: section.text }}></div>
                  </div>
                </div>
              );
            }
          })}
        </div>
        <div className={css.bottomInfo}>
          <div className={css.wrapper}>
            <p className="text-2">{_t('bottomInfo.paragraph1')}</p>
            <p className="text-2">{_t('bottomInfo.paragraph2')}</p>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default AI;
