import React from 'react';
import cn from 'classnames';
import heroImg from 'assets/images/cards_type/hero.png';
import PageLayout from 'components/common/PageLayout';
import Menu from './Menu';
import Card from './Card';
import CardsLogic from './CardsLogic';
import { cards, text } from './data';
import css from './CardsFormat.module.scss';

type Props = {};

const CardsFormat: React.FC<Props> = () => {
  return (
    <PageLayout className={css.page} contentClassName={css.pageContent}>
      <Menu />

      <img className={css.heroImg} src={heroImg} alt="" />
      <h1 className={cn(css.heroTitle, 'text-5', 'font-semibold')} id="title">
        {text('title')}
      </h1>
      <div className={cn(css.heroInfo, 'grid', 'grid-cols-2')}>
        <h3 className="text-3 font-semibold">{text('heroInfo.title')}</h3>
        <p className="text-2">{text('heroInfo.text')}</p>
      </div>

      <CardsLogic />

      <div className={cn(css.cards)}>
        <h3 className={cn(css.title, 'text-5', 'font-semibold')}>{text('cardTypes.title')}</h3>
        {cards.map((card) => (
          <Card key={card.id} {...card} />
        ))}
      </div>
    </PageLayout>
  );
};

export default CardsFormat;
