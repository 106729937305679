import React from "react";
import css from "./styles.module.scss";

type Props = {
  className?: string;
  text: string;
  title?: string;
  renderIcon?: Function | null;
};

const InfoBox: React.FC<Props> = ({
  className = "",
  title = "",
  renderIcon = null,
  text,
}: React.ComponentProps<typeof InfoBox>) => {
  return (
    <div className={`${css.infoBox} ${className}`}>
      {renderIcon && <div className={css.iconWrap}>{renderIcon()}</div>}
      {title && <div className={css.title}>{title}</div>}
      <div className={css.text}>{text}</div>
    </div>
  );
};

export default InfoBox;
