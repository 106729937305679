const STORYCARDS_DOMAIN = 'https://storycards.com';
const STORYCARDS_APP = 'https://my.story-cards.com';

export const ROOT_ROUTE = '/';
export const HOME_PAGE_NEW_ROUTE = `${ROOT_ROUTE}homepage-new`;
export const STARTUPS_ROUTE = `${ROOT_ROUTE}startups`;
export const AGENCIES_ROUTE = `${ROOT_ROUTE}agencies`;
export const PUBLISHERS_ROUTE = `${ROOT_ROUTE}publishers`;
export const FLOW_ROUTE = `${ROOT_ROUTE}flow`;
export const SC_EDITOR_ROUTE = `${ROOT_ROUTE}editor`;
export const DATA_AND_PRIVACY_ROUTE = `${ROOT_ROUTE}data-collection`;
export const ANALYTICS_ROUTE = `${ROOT_ROUTE}analytics`;
export const SEGMENTS_ROUTE = `${ROOT_ROUTE}create-segments`;
export const CARDS_FORMAT_ROUTE = `${ROOT_ROUTE}cards-format`;
export const PRICING_ROUTE = `${ROOT_ROUTE}pricing`;
export const ABOUT_ROUTE = `${ROOT_ROUTE}about`;
export const COLLABORATION_ROUTE = `${ROOT_ROUTE}collaboration`;
export const GIL_ROUTE = `${ROOT_ROUTE}gil-rabbi`;
export const INVITE_ROUTE = `${ROOT_ROUTE}invite`;
export const PRESS_CENTER_ROUTE = `${ROOT_ROUTE}press-center`;
export const PRIVACY_POLICY_ROUTE = `${ROOT_ROUTE}privacy-policy`;
export const DPA_ROUTE = `${ROOT_ROUTE}dpa`;
export const NOT_FOUND_ROUTE = `${ROOT_ROUTE}not-found`;
export const BRAND_COLOR = '#00A87E';
export const INSPIRATION_LINK = 'https://inspire.storycards.com';
export const NEW_SIGN_UP = 'https://signup.storycards.com/new';
export const AI_ROUTE = `${ROOT_ROUTE}ai`;

export { STORYCARDS_DOMAIN, STORYCARDS_APP };
