/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import cn from 'classnames';
import { LinkWithQuery } from '../LinkWithQuery';
import {
  ROOT_ROUTE,
  SC_EDITOR_ROUTE,
  ANALYTICS_ROUTE,
  SEGMENTS_ROUTE,
  FLOW_ROUTE,
  DATA_AND_PRIVACY_ROUTE,
  PRICING_ROUTE,
  CARDS_FORMAT_ROUTE,
  COLLABORATION_ROUTE,
  ABOUT_ROUTE,
  PRESS_CENTER_ROUTE,
  PRIVACY_POLICY_ROUTE,
  DPA_ROUTE,
  INSPIRATION_LINK,
  AI_ROUTE,
} from 'components/constants';
import icons from 'assets/icons';
import translator from 'utils/translator';

import styles from './styles.module.scss';

interface MenuItemType {
  label: string;
  url: string;
  target?: string;
  isLast?: boolean;
}

interface Props {
  isPricingPage?: boolean;
}

const _t = (path: string, pattern: object = {}) => translator.text(`${path}`, pattern);

function Footer(props: Props) {
  const titleContent = 'Create custom engagement products, with our no code editor';
  const textContent =
    'Storycards is a no-code platform empowering enterprises to create premium data collection products on a fully visual canvas, powered by AI and no-code technology';
  const CTALabel = 'Start Creating';

  return (
    <footer id="footer" className={`${styles.footer} w-full text-white`}>
      <div className={`${styles.s1} grid grid-cols-2`}>
        <div className={styles.s1Left}>
          <h5 className={`${styles.title} font-semibold`}>{titleContent}</h5>
        </div>

        <div className={`${styles.s1Right} flex justify-between items-center flex-wrap`}>
          <p className={`${styles.text} `}>{textContent}</p>
          {!props.isPricingPage && (
            <LinkWithQuery to={PRICING_ROUTE} className={`${styles.CTAButton} rounded-full`}>
              {CTALabel}
            </LinkWithQuery>
          )}
        </div>
      </div>
      <div className={`${styles.s2} flex justify-between items-start`}>
        <LinkWithQuery to={ROOT_ROUTE} className={`${styles.logo} `}>
          <img src={icons.storycardsLogoWhite} alt="Storycards" />
        </LinkWithQuery>

        <div className={`${styles.footerLinks}`}>
          {links.map(({ url, label, target, isLast }) => {
            return target === '_blank' ? (
              <a className={cn(styles.link, { [styles.last]: isLast })} key={label} href={url} target={target}>
                {label}
              </a>
            ) : (
              <LinkWithQuery className={cn(styles.link, { [styles.last]: isLast })} key={label} to={url}>
                {label}
              </LinkWithQuery>
            );
          })}
        </div>
        <div className={`${styles.socials}`}>
          <div className={`${styles.socialsInner} flex justify-between items-center`}>
            {socials.map((item) => (
              <a href={item.url} key={item.url} className={`${styles[item.name]} `} target="_blank">
                <img src={item.image} alt={`icon ${item.name}`} />
              </a>
            ))}
          </div>
        </div>
      </div>
      <p className={styles.copyright}>© {new Date().getFullYear()} Storycards, Inc. All rights reserved.</p>
    </footer>
  );
}

export default Footer;

Footer.defaultProps = {
  isPricingPage: false,
};

const links: MenuItemType[] = [
  { label: _t('menu.editor.title'), url: SC_EDITOR_ROUTE },
  { label: _t('menu.dataCollection.title'), url: DATA_AND_PRIVACY_ROUTE },
  { label: _t('menu.privacy'), url: PRIVACY_POLICY_ROUTE, isLast: true },
  { label: _t('menu.flow.title'), url: FLOW_ROUTE },
  { label: _t('menu.segments.title'), url: SEGMENTS_ROUTE },
  { label: _t('menu.careers'), url: '/' },
  { label: _t('menu.cardsFormat.title'), url: CARDS_FORMAT_ROUTE },
  { label: _t('menu.pricing'), url: PRICING_ROUTE },
  { label: _t('menu.inspiration'), url: INSPIRATION_LINK, target: '_blank' },
  { label: _t('menu.ai'), url: AI_ROUTE },
  { label: _t('menu.about'), url: ABOUT_ROUTE },
  { label: _t('menu.collaboration.title'), url: COLLABORATION_ROUTE },
  {
    label: _t('menu.tos'),
    url: `${window.location.origin}/terms.pdf?d=08022022`,
    target: '_blank',
    isLast: true,
  },
  { label: _t('menu.pressCenter'), url: PRESS_CENTER_ROUTE },

  { label: _t('menu.analytics.title'), url: ANALYTICS_ROUTE },
  { label: _t('menu.dpa'), url: DPA_ROUTE, isLast: true },
];

const socials = [
  {
    name: 'facebook',
    image: icons.facebookIcon,
    url: 'https://www.facebook.com/my.storycards',
  },
  {
    name: 'instagram',
    image: icons.instagramIcon,
    url: 'https://www.instagram.com/_storycards',
  },
  {
    name: 'youtube',
    image: icons.youtubeIcon,
    url: 'https://www.youtube.com/channel/UC_tzryUqiQv2UjAyhFR-vOw',
  },
  {
    name: 'twitter',
    image: icons.twitterIcon,
    url: 'https://twitter.com/_Storycards',
  },
  {
    name: 'linkedin',
    image: icons.linkedinIcon,
    url: 'https://www.linkedin.com/company/storycards-app',
  },
];
