import React from "react";
import images from "assets/images";

import translator from "utils/translator";
import PageLayout from "components/common/PageLayout";
import css from "./styles.module.scss";

const _t = (path: string) => translator.text(`gilRabbiPage.${path}`);

export default function GilPage() {
  return (
    <PageLayout className={css.gilPage} contentClassName={css.contentLayout}>
      <div className={css.wrapper}>
        <div className={css.firstSection}>
          <div className={css.topImage}>
            <img
              src={images.ceoRabbi}
              alt="Gil Rabbi"
              title="Gil Rabbi"
              className={css.smallGil}
            />
            <div className={css.topImageTexts}>
              <h1 className={css.name}>{_t("gilRabbi")}</h1>
              <p className={css.whoAmI}>{_t("founderAndSeo")}</p>
            </div>
          </div>
          <div className={css.topTextBlock}>
            <p className={css.title}>{_t("firstTitle")}</p>
            <p
              className={css.subtitle}
              dangerouslySetInnerHTML={{ __html: _t("firstSubtitle") }}
            ></p>
          </div>
        </div>

        <img
          src={images.gilRabbi}
          alt="Gil Rabbi"
          title="Gil Rabbi"
          className={css.bigGil}
        />
        <div className={css.secondSection}>
          <h2 className={css.title}>{_t("secondTitle")}</h2>
          <p
            className={css.subtitle}
            dangerouslySetInnerHTML={{ __html: _t("secondSubtitle") }}
          ></p>
        </div>
      </div>
    </PageLayout>
  );
}
