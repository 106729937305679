import storycardsLogo from "./storycards_logo.svg";
import storycardsLogoWhite from "./storycards_logo_white.svg";
import storycardsLogoNoText from "./storycards_logo_no_text.svg";
import pathIcon from "./path_icon.svg";
import partner1 from "./partner1.png";
import partner2 from "./partner2.png";
import partner3 from "./partner3.png";
import partner4 from "./partner4.png";
import partner5 from "./partner5.png";
import partner6 from "./partner6.png";
import partner7 from "./partner7.png";
import partner8 from "./partner8.png";
import partner9 from "./partner9.png";
import facebookIcon from "./facebook_icon.svg";
import instagramIcon from "./instagram_icon.svg";
import youtubeIcon from "./youtube_icon.svg";
import twitterIcon from "./twitter_icon.svg";
import hamburgerIcon from "./hamburger_icon.svg";
import linkedinIcon from "./linkedin_icon.svg";
import lockIcon from "./lock_icon.svg";
import moneyBackIcon from "./money_back_icon.svg";
import creditcardIcon from "./credit_card_icon.svg";
import payments from "./payments.jpeg";
import mute from "./mute.svg";
import unmute from "./unmute.svg";

const icons = {
  lockIcon,
  moneyBackIcon,
  payments,
  creditcardIcon,
  storycardsLogo,
  storycardsLogoWhite,
  storycardsLogoNoText,
  pathIcon,
  partner1,
  partner2,
  partner3,
  partner4,
  partner5,
  partner6,
  partner7,
  partner8,
  partner9,
  facebookIcon,
  instagramIcon,
  youtubeIcon,
  twitterIcon,
  linkedinIcon,
  hamburgerIcon,
  mute,
  unmute,
};

export default icons;
