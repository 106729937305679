import blurredBg from './blurred_background.png';
import videoImage from './video_image.jpg';
import card1Image from './card_1.png';
import card2Image from './card_2.svg';
import card3Image from './card_3.svg';
import card4Image from './card_4.svg';
import card5Image from './card_5.svg';
import animationPlaceholder from './animation_placeholder.png';
import info1Image from './info_1.jpeg';
import info2Image from './info_2.jpg';
import info3Image from './info_3.png';
import info4Image from './info_4.jpg';
import info5Image from './info_5.png';
import flowImage from './flow_image.jpeg';
import trueOrFalseImage from './cards_type/true-or-false-image.jpg';
import gilRabbi from './gilPage/Gil_Rabbi.jpg';
import ceoRabbi from './about/ceo.png';
import collaborationImage from './collaboration.jpeg';
import dataImage from './data_image.jpeg';
import meetStoryCardsVideo from './meet_storycards_video.jpeg';
import aiTopBgMob from './ai/top-section-mob.jpg';
import aiTopBgDesk from './ai/top-section-desk.jpg';

const images = {
  meetStoryCardsVideo,
  flowImage,
  dataImage,
  card1Image,
  card2Image,
  card3Image,
  card4Image,
  card5Image,
  blurredBg,
  videoImage,
  animationPlaceholder,
  info1Image,
  info2Image,
  info3Image,
  info4Image,
  info5Image,
  trueOrFalseImage,
  collaborationImage,
  gilRabbi,
  ceoRabbi,
  aiTopBgMob,
  aiTopBgDesk,
};

export default images;
