import gsap, { Power0, Power2 } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

import styles from "./styles.module.scss";

interface IAnimation {
  destroy(): void;
}

type AnimationParams = {
}

enum STEP {
  'INTRO',
  'DESIGN_WITH_FREEDOM',
  'EMBED_IT',
  'COMMUNICATE_AND_SEGMENTS'
}

const instance = Symbol('instance');
const vw = (px: number, base: number = 1440) =>  px / base * window.innerWidth;

gsap.registerPlugin(ScrollTrigger);
gsap.defaults({ ease: Power0.easeNone });

export default class Animation implements IAnimation {
  static [instance]: Animation | null;
  static getInstance() {
    return Animation[instance];
  }

  private containerEl: HTMLDivElement;
  private headerEl: HTMLDivElement;
  private fixedContentEl: HTMLDivElement;
  private videoContainerEl: HTMLVideoElement;
  private videoEl: HTMLVideoElement;
  private textsEl: HTMLDivElement;

  private step: STEP;
  private scrollHelper: { scrollTop: number, isActive: boolean };
  private timing: Record<STEP, number>;
  private isStepBackDetected: boolean = false;
  
  private mainScrollTrigger: ScrollTrigger | null = null;
  private videoStartingScrollTrigger: ScrollTrigger | null = null;
  private videoSize: 'mini' | 'full' = 'full';

  private readonly VIDEO_DURATION = 34;

  constructor(params?: AnimationParams) {
    if (Animation[instance]) {
      throw new Error('Can not instantiate more than one instance. Use Animation.getInstance()')
    }

    this.timing = {
      [STEP.INTRO]: 12.5,
      [STEP.DESIGN_WITH_FREEDOM]: 22,
      [STEP.EMBED_IT]: 28,
      [STEP.COMMUNICATE_AND_SEGMENTS]: this.VIDEO_DURATION,
    };
    this.step = STEP.INTRO;
    this.scrollHelper = { scrollTop: window.pageYOffset, isActive: false };

    this.containerEl = document.querySelector(`.${styles.scrollingSection}`) as HTMLDivElement;
    this.headerEl = this.containerEl?.querySelector(`.${styles.header}`) as HTMLDivElement;
    this.fixedContentEl = document.querySelector(`.${styles.fixedContent}`) as HTMLDivElement;
    this.videoContainerEl = this.containerEl?.querySelector(`.${styles.bgVideo}`) as HTMLVideoElement;
    this.videoEl = this.containerEl?.querySelector(`.${styles.bgVideo} video`) as HTMLVideoElement;
    this.textsEl = this.containerEl?.querySelector(`.${styles.scrollingTextInner}`) as HTMLDivElement;

    this.videoEl.addEventListener('timeupdate', this.onVideoEventListener);

    this.initScrollTriggers();
    Animation[instance] = this;
  }

  private get currentStepTimeLimit() {
    return this.timing[this.step];
  }

  private onVideoEventListener = (e: Event) => {
    switch (e.type) {
      case 'timeupdate': {
        let timeLimit = this.currentStepTimeLimit;
        if (this.step === STEP.INTRO && this.isStepBackDetected === false) {
          timeLimit += 0.9;
        }
        if (this.videoEl.currentTime > timeLimit) {
          if (this.isStepBackDetected) {
            this.videoEl.pause();
          } else {
            this.setStep(this.step + 1);
          }
        }
        break;
      }
     
      default:
        break;
    }
  }

  private initScrollTriggers = () => {
    // video starting:
    this.videoStartingScrollTrigger = ScrollTrigger.create({
      trigger: `.${styles.fixedContent}`,
      start: 'top 25%',
      end: '+=100% bottom',
      onEnter: () => this.videoEl.play(),
      onLeaveBack: () => {
        this.isStepBackDetected = true;
      }
    });

    // main content:
    this.mainScrollTrigger = ScrollTrigger.create({
      trigger: `.${styles.fixedContent}`,
      animation: gsap
        .timeline()
        .to(this.textsEl, { y: '-200%', duration: 1 }, 0),
      pin: true,
      scrub: true,
      start: () => `top top`,
      end: () => `+=${this.fixedContentEl.offsetHeight * 4} top`,
      onUpdate: trigger => {
        const stepProgress = 1 / 3;
        const steps = [];
        let currentStep = STEP.INTRO;

        steps[STEP.INTRO] = stepProgress * 0.5;
        steps[STEP.DESIGN_WITH_FREEDOM] = steps[STEP.INTRO] + stepProgress;
        steps[STEP.EMBED_IT] = steps[STEP.DESIGN_WITH_FREEDOM] + stepProgress;
        steps[STEP.COMMUNICATE_AND_SEGMENTS] = 1;
        
        for (let i = 0; i < steps.length; i++) {
          if (trigger.progress <= steps[i]) {
            currentStep = i;
            break;
          }
        };

        if (!this.scrollHelper.isActive) {
          this.setStep(currentStep, false);
        }
      },
    });
  }

  private scrollTo = (scrollTop: number, time: number = 1) => {
    gsap.killTweensOf(this.scrollHelper);
    gsap.fromTo(
      this.scrollHelper,
      {
        scrollTop: window.pageYOffset,
      },
      {
        scrollTop,
        duration: time,
        ease: Power2.easeInOut,
        onStart: () => {
          this.scrollHelper.isActive = true;
        },
        onUpdate: () => {
          window.scrollTo(0, this.scrollHelper.scrollTop);
        },
        onComplete: () => {
          this.scrollHelper.isActive = false;
        },
      }
    );
  }

  private setVideoSize = (size: 'mini' | 'full') => {
    if (this.videoSize === size) return;

    let targetWidth, targetHeight;
    const { innerWidth, innerHeight } = window;
    const { videoWidth, videoHeight } = this.videoEl;
    const ratio = videoHeight / videoWidth;
    const isMini = size === 'mini';

    targetWidth = innerWidth * 0.5;
    targetHeight = targetWidth * ratio;

    gsap.to(this.videoContainerEl, {
      width: isMini ? targetWidth : innerWidth,
      height: isMini ? targetHeight : innerHeight,
      x: isMini ? vw(-100) : 0,
      y: isMini ? vw(100) : 0,
      borderRadius: isMini ? vw(40) : 0,
      ease: 'elastic.out(1, 0.5)',
      duration: 1.35,
    });
  
    this.videoSize = size;
  }

  private setStep = (step: STEP, autoScroll: boolean = true) => {
    const stepsAmount = 4;
    if (step === this.step || step < 0 || step > stepsAmount - 1) return;
    if (!this.mainScrollTrigger) throw new Error('this.mainScrollTrigger is null or undefined');

    switch (step) {
      case STEP.INTRO:
        this.videoEl.play();
        this.setVideoSize('full');
        break;

      case STEP.DESIGN_WITH_FREEDOM:
      case STEP.EMBED_IT:
      case STEP.COMMUNICATE_AND_SEGMENTS: {
        const steps = 3;
        const stepProgress = 1 / steps;
        const scrollDistance = this.mainScrollTrigger.end - this.mainScrollTrigger.start;
        const textStep = step - STEP.INTRO;
        const targetScroll = this.mainScrollTrigger.start + scrollDistance * stepProgress * textStep;

        if (autoScroll && targetScroll > window.pageYOffset) {
          this.scrollTo(targetScroll);
        }

        this.setVideoSize('mini');
        break;
      }
    
      default:
        break;
    }

    if (!autoScroll) {
      if (step === STEP.DESIGN_WITH_FREEDOM) {
        // @ts-ignore
        this.videoEl.currentTime = this.timing[step - 1] + 0.9 || 0;
      } else {
        // @ts-ignore
        this.videoEl.currentTime = this.timing[step - 1] || 0;
      }
      if (this.videoEl.paused) this.videoEl.play();
    }

    if (step < this.step) {
      this.isStepBackDetected = true;
    }
    // console.info(`%csetStep: ${step}`, 'color: yellow');
    this.step = step;
  }

  destroy = () => {
    this.mainScrollTrigger?.kill();
    this.videoStartingScrollTrigger?.kill();

    this.videoEl.removeEventListener('timeupdate', this.onVideoEventListener);
    
    Animation[instance] = null;
  };
};