// @ts-nocheck

import React, { useEffect } from "react";
import cn from "classnames";
import css from "./EmbedLoader.module.scss";

type Props = {
  scriptSrc: string;
  embedID: string; // ex: cdZBXHi1, invite
};

function EmbedStory(props: Props) {
  const { embedID, scriptSrc } = props;

  const scriptLoaded = () => {
    window.embedStory(`storycards/${embedID}`, {
      autoHeight: true,
      start: "in-view",
    });
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.src = scriptSrc;
    script.async = true;
    script.dataset.id = embedID;
    script.onload = () => scriptLoaded();

    document.body.appendChild(script);
    //eslint-disable-next-line
  }, []);
  return (
    <div
      id={`story-storycards/${embedID}`}
      className={cn(
        `h-full w-full flex items-start justify-center`,
        css.embedLoader
      )}
    ></div>
  );
}

export default EmbedStory;
