import { NavLink, useLocation } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import _ from 'lodash';
import cn from 'classnames';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import {
  SC_EDITOR_ROUTE,
  ANALYTICS_ROUTE,
  SEGMENTS_ROUTE,
  FLOW_ROUTE,
  DATA_AND_PRIVACY_ROUTE,
  PRICING_ROUTE,
  CARDS_FORMAT_ROUTE,
  COLLABORATION_ROUTE,
  ABOUT_ROUTE,
  ROOT_ROUTE,
  STARTUPS_ROUTE,
  AGENCIES_ROUTE,
  PUBLISHERS_ROUTE,
  BRAND_COLOR,
  INSPIRATION_LINK,
  AI_ROUTE,
} from 'components/constants';
import icons from 'assets/icons';
import useResponsiveQuery from 'utils/useResponsiveQuery';
import { STORYCARDS_APP } from 'components/constants';
import { dataLayerEvents } from 'utils/gtag';
import translator from 'utils/translator';
import DropdownMenu, { DropdownId } from 'components/common/Header/DropdownMenu';
import MobileLogo from './MobileLogo';
import { LinkWithQuery } from '../LinkWithQuery';
import HamburgerIcon from 'components/icons/HamburgerIcon';
import styles from './styles.module.scss';

type Props = {
  invertColors?: boolean;
  isTransparentHeader?: boolean;
  isPricingPage?: boolean;
  isWhite?: boolean;
};
interface MenuItemType {
  label: string;
  url?: string;
  dropdown?: { label: string; subtitle?: string; url: string }[];
  dropdownId?: DropdownId;
  target?: string;
  hideOnDesk?: boolean;
}

const _t = (path: string, pattern: object = {}) => translator.text(`${path}`, pattern);

const menus: MenuItemType[] = [
  {
    label: _t('menu.solutions'),
    dropdownId: 'solutions',
    dropdown: [
      { label: _t('menu.startups.title'), subtitle: _t('menu.startups.subtitle'), url: STARTUPS_ROUTE },
      { label: _t('menu.agencies.title'), subtitle: _t('menu.agencies.subtitle'), url: AGENCIES_ROUTE },
      { label: _t('menu.publishers.title'), subtitle: _t('menu.publishers.subtitle'), url: PUBLISHERS_ROUTE },
    ],
  },
  {
    label: _t('menu.product'),
    dropdownId: 'product',
    dropdown: [
      { label: _t('menu.editor.title'), subtitle: _t('menu.editor.subtitle'), url: SC_EDITOR_ROUTE },
      { label: _t('menu.flow.title'), subtitle: _t('menu.flow.subtitle'), url: FLOW_ROUTE },
      { label: _t('menu.cardsFormat.title'), subtitle: _t('menu.cardsFormat.subtitle'), url: CARDS_FORMAT_ROUTE },
      { label: _t('menu.collaboration.title'), subtitle: _t('menu.collaboration.subtitle'), url: COLLABORATION_ROUTE },
    ],
  },
  {
    label: _t('menu.dataAndAnalytics'),
    dropdownId: 'analytics',
    dropdown: [
      { label: _t('menu.analytics.title'), subtitle: _t('menu.analytics.subtitle'), url: ANALYTICS_ROUTE },
      {
        label: _t('menu.dataCollection.title'),
        subtitle: _t('menu.dataCollection.subtitle'),
        url: DATA_AND_PRIVACY_ROUTE,
      },
      { label: _t('menu.segments.title'), subtitle: _t('menu.segments.subtitle'), url: SEGMENTS_ROUTE },
    ],
  },
  { label: _t('menu.ai'), url: AI_ROUTE },
  { label: _t('menu.about'), url: ABOUT_ROUTE, hideOnDesk: true },
  // { label: _t('menu.pricing'), url: PRICING_ROUTE },
  { label: _t('menu.inspiration'), url: INSPIRATION_LINK, target: '_blank' },
];

let lastScrollTop = 0;
let isHideHeaderGlobal = false;
function Header({ invertColors, isPricingPage, isWhite = true }: Props) {
  const isMobile = useResponsiveQuery();
  const [navbarShown, setNavbarShown] = useState(false);
  const [isMinimalHeader, setIsMinimalHeader] = useState(false);
  const [isHideHeader, setIsHideHeader] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeDropdownId, setActiveDropdownId] = useState<MenuItemType['dropdownId']>();
  const ref = useRef<HTMLElement>(null);
  const scrollHeight = useRef(1);
  const onOrientationChange = () => {
    setIsMenuOpen(false);
  };

  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  if (document.referrer) urlParams.append('referrer', document.referrer);
  const pricingUrlParamsStr = urlParams.toString().length > 0 ? `?${urlParams.toString()}` : '';
  const pricingUrl = `${PRICING_ROUTE}${pricingUrlParamsStr}`;

  useEffect(() => {
    const onScroll = () => {
      let st = window.scrollY || document.documentElement.scrollTop;

      if (window.scrollY === 0) {
        setIsMinimalHeader(false);
        return;
      }

      if (st > lastScrollTop) {
        // downscroll code
        if (st > 0 && !isHideHeaderGlobal) {
          isHideHeaderGlobal = true;
          setIsHideHeader(true);
          setIsMinimalHeader(true);
          setActiveDropdownId(undefined);
        }
      } else if (st < lastScrollTop) {
        // upscroll code
        if (st > 0 && isHideHeaderGlobal) {
          isHideHeaderGlobal = false;
          setIsHideHeader(false);
        }
      }
      lastScrollTop = st <= 0 ? 0 : st;
    };

    const onWindowResize = () => {
      scrollHeight.current = document.body.scrollHeight;
    };

    window.addEventListener('scroll', onScroll, true);
    window.addEventListener('resize', onWindowResize);
    onWindowResize();

    return () => {
      lastScrollTop = 0;
      isHideHeaderGlobal = false;
      window.removeEventListener('scroll', onScroll, true);
      window.removeEventListener('resize', onWindowResize);
    };
  }, [isMobile]);

  // disable content scrolling if menu opened
  useEffect(() => {
    if (isMenuOpen) {
      disableBodyScroll(document.body);
      document.documentElement.style.cssText = 'height:100vh !important';

      document.documentElement.classList.add(`${styles.disableScroll}`);
    } else {
      enableBodyScroll(document.body);
      document.documentElement.style.cssText = 'height: auto !important';

      document.documentElement.classList.remove(`${styles.disableScroll}`);
    }
  }, [isMenuOpen]);

  // Fix bug with change orientation
  useEffect(() => {
    window.addEventListener('orientationchange', onOrientationChange);

    return () => {
      window.removeEventListener('orientationchange', onOrientationChange);
    };
  }, []);

  const handleMenu = () => {
    setIsMenuOpen((isOpen) => {
      if (isOpen) setActiveDropdownId(undefined);
      return !isOpen;
    });
  };

  const renderMenuLinks = () => {
    return menus.map((item) => {
      if (!isMobile && item.hideOnDesk) return null;
      if (item.url && item.target === '_blank') {
        return (
          <a
            key={`menu-item-${item.dropdownId}-${item.url}`}
            href={item.url}
            target="_blank"
            rel="noreferrer"
            className={cn(styles.navLink, !isWhite && styles.whiteText, isMinimalHeader && styles.blackText)}
          >
            {item.label}
          </a>
        );
      }
      return !item.url && item.dropdown ? (
        <DropdownMenu
          key={item.label}
          invertColors={invertColors}
          title={item.label}
          titleClassName={cn(
            styles.navLink,
            !isWhite && styles.whiteText,
            isMinimalHeader && styles.blackText,
            'cursor-pointer',
            {
              [styles.navLinkActive]: item.dropdown.some(({ url }) => url === window.location.pathname),
            },
          )}
          dropdownLinks={item.dropdown}
          id={item.dropdownId}
          activeDropdownId={activeDropdownId}
          onClick={setActiveDropdownId}
        />
      ) : (
        <NavLink
          to={`${item.url}${search}`}
          key={item.label}
          activeClassName={styles.navLinkActive}
          className={cn(
            styles.navLink,
            !isWhite && styles.whiteText,
            isMinimalHeader && styles.blackText,
            'cursor-pointer',
          )}
        >
          {item.label}
        </NavLink>
      );
    });
  };

  return (
    <header
      ref={ref}
      className={cn(
        styles.header,
        !isMobile && isWhite && styles.whiteHeader,
        !isMobile && isHideHeader && styles.hide,
        !isMobile && isMinimalHeader && styles.minimal,
        'flex',
        'fixed',
        'duration-300',
        'z-40',
        'w-full',
        'items-center',
        {
          [styles.openedMobileMenu]: isMenuOpen,
          [styles.inverted]: invertColors,
          'shadow-md': navbarShown,
        },
      )}
      id="header"
    >
      <div className={cn('flex', 'w-full', 'items-center')}>
        <div className={cn('flex', 'flex-1', 'items-center')}>
          <div className={cn('flex', 'relative')}>
            {isMobile ? (
              <MobileLogo />
            ) : (
              <>
                <h1
                  className={cn(
                    'flex-1',
                    'duration-300',
                    styles.bigLogo,
                    !isMobile && isMinimalHeader && styles.hidden,
                  )}
                  onClick={() => dataLayerEvents.onHeaderLogoClick()}
                >
                  <LinkWithQuery to={ROOT_ROUTE}>
                    <img
                      className={`${styles.logo} `}
                      src={isWhite ? icons.storycardsLogo : icons.storycardsLogoWhite}
                      title="Storycards"
                      alt="Storycards"
                    />
                  </LinkWithQuery>
                </h1>
                <h1
                  className={cn(
                    'flex-1',
                    'duration-300',
                    styles.smallLogo,
                    !isMobile && isMinimalHeader && styles.visible,
                  )}
                  onClick={() => dataLayerEvents.onHeaderLogoClick()}
                >
                  <LinkWithQuery to={ROOT_ROUTE} className={styles.littleLogo}>
                    <img
                      className={styles.logoNoText}
                      src={icons.storycardsLogoNoText}
                      title="Storycards"
                      alt="Storycards"
                      itemProp="image"
                    />
                  </LinkWithQuery>
                </h1>
              </>
            )}

            {!isMobile && (
              <div
                className={cn(
                  styles.menu,
                  isMinimalHeader && styles.minimalLeft,
                  'flex',
                  'flex-1',
                  'items-center',
                  'relative',
                  'whitespace-nowrap',
                )}
              >
                {renderMenuLinks()}
              </div>
            )}
          </div>
        </div>

        {!isMobile && (
          <a
            href={STORYCARDS_APP}
            target="_blank"
            rel="noopener noreferrer"
            className={cn(styles.loginButton, !isWhite && styles.whiteBorder, 'rounded-full', 'font-semibold')}
            onClick={() => dataLayerEvents.onHeaderLoginClick()}
          >
            {_t('header.loginBtnLabel')}
          </a>
        )}
        {!isMobile && !isPricingPage && (
          <a
            href={pricingUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={cn(styles.createButton, 'text-white', 'rounded-full', 'font-semibold')}
            onClick={() => dataLayerEvents.onHeaderStartCreatingClick()}
          >
            Start Creating
          </a>
        )}

        {isMobile && (
          <>
            <div className={styles.hamburgerWrapper}>
              {isPricingPage ? (
                <a
                  href={STORYCARDS_APP}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${styles.loginButton} ${styles.loginButtonMobile} rounded-full font-semibold`}
                  style={{
                    backgroundColor: invertColors ? 'inherit' : '#fff',
                    color: BRAND_COLOR,
                    border: !navbarShown ? 'solid 1px #CDCDCD' : 'solid 1px #ccc',
                  }}
                  onClick={() => dataLayerEvents.onHeaderLoginClick()}
                >
                  {_t('header.loginBtnLabel')}
                </a>
              ) : !isMenuOpen ? (
                <a
                  href={pricingUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${styles.loginButton} ${styles.startButtonMobile} rounded-full font-semibold`}
                  style={{
                    backgroundColor: BRAND_COLOR,
                    color: '#FFF',
                    border: 'none',
                  }}
                  onClick={() => dataLayerEvents.onHeaderLoginClick()}
                >
                  {_t('header.startBtnLabelMobile')}
                </a>
              ) : (
                <a
                  href={STORYCARDS_APP}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${styles.loginButton} ${styles.loginButtonMobile} rounded-full font-semibold`}
                  style={{
                    backgroundColor: invertColors ? 'inherit' : '#fff',
                    color: BRAND_COLOR,
                    border: 'solid 1px #CDCDCD',
                  }}
                  onClick={() => dataLayerEvents.onHeaderLoginClick()}
                >
                  {_t('header.loginBtnLabel')}
                </a>
              )}

              <button className={`${styles.hamburgerButton} `} onClick={handleMenu}>
                {isMenuOpen ? (
                  <span className={styles.closeIcon}>
                    <span className={styles.line} />
                    <span className={styles.line} />
                  </span>
                ) : (
                  <HamburgerIcon color={invertColors ? 'white' : 'black'} />
                )}
              </button>
            </div>
            {createPortal(
              <div
                className={cn(
                  styles.mobileMenu,
                  isMenuOpen && styles.open,
                  'fixed top-0 bottom-0 left-0 right-0 w-full h-full overflow-auto',
                )}
              >
                <div className="flex flex-col">{renderMenuLinks()}</div>
              </div>,
              document.body,
            )}
          </>
        )}
      </div>
    </header>
  );
}

export default Header;

Header.defaultProps = {
  isPricingPage: false,
  isTransparentHeader: false,
  invertColors: false,
};
