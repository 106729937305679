import vk from './vk.png';
import vg from './vg.png';
import ap from './ap.png';
import vi from './vi.png';
import ay from './ay.png';
import ly from './ly.png';
import master from './master.png';
import vp from './vp.png';
import shurik from './shurik.png';
import ceo from './ceo.png';
import hero from './hero.jpg';
import max from './max.png';

const images = {
  vk,
  vg,
  ap,
  vi,
  ay,
  ly,
  master,
  vp,
  shurik,
  ceo,
  hero,
  max,
};

export default images;
