import React from 'react';

import translator from 'utils/translator';

import ScrollSection from '../ScrollSection';
import styles from './styles.module.scss';

const _t = (path: string) => translator.text(`homePageNew.${path}`);

function Create() {
  return (
    <div className={`${styles.create} flex flex-col w-full items-center`}>
      <div className={styles.content}>
        <h2>{_t('create.title')}</h2>
        <p className={styles.subtitle}>{_t('create.subtitle')}</p>

        <ScrollSection sectionId="create" />
      </div>
    </div>
  );
}
export default Create;
