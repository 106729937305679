import React from 'react';
import { BRAND_COLOR } from 'components/constants';

const RoundCheck = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="42px" height="42px" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-153.000000, -834.000000)">
        <rect fill="#FFFFFF" opacity="0.87" x="0" y="0" width="1440" height="2190" />
        <g transform="translate(152.000000, 843.000000)">
          <g transform="translate(10.000000, 0.000000)">
            <g transform="translate(-12.041739, -12.041739)">
              <polygon points="0 0 48.0834783 0 48.0834783 48.0834783 0 48.0834783" />
              <g opacity="0.5" transform="translate(6.010435, 6.010435)" fill={BRAND_COLOR} fillOpacity="0.7">
                <path d="M18.0313043,0 C8.07802435,0 0,8.07802435 0,18.0313043 C0,27.9845843 8.07802435,36.0626087 18.0313043,36.0626087 C27.9845843,36.0626087 36.0626087,27.9845843 36.0626087,18.0313043 C36.0626087,8.07802435 27.9845843,0 18.0313043,0 Z" />
              </g>
              <path
                d="M24.0417391,4.00695652 C12.9825391,4.00695652 4.00695652,12.9825391 4.00695652,24.0417391 C4.00695652,35.1009391 12.9825391,44.0765217 24.0417391,44.0765217 C35.1009391,44.0765217 44.0765217,35.1009391 44.0765217,24.0417391 C44.0765217,12.9825391 35.1009391,4.00695652 24.0417391,4.00695652 Z M24.0417391,6.01043478 C14.0884591,6.01043478 6.01043478,14.0884591 6.01043478,24.0417391 C6.01043478,33.9950191 14.0884591,42.0730435 24.0417391,42.0730435 C33.9950191,42.0730435 42.0730435,33.9950191 42.0730435,24.0417391 C42.0730435,14.0884591 33.9950191,6.01043478 24.0417391,6.01043478 Z M20.0347826,34.0591304 L12.0208696,26.0452174 L14.8457739,23.220313 L20.0347826,28.389287 L33.2377043,15.1863652 L36.0626087,18.0313043 L20.0347826,34.0591304 Z"
                fill={BRAND_COLOR}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default RoundCheck;
