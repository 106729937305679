/* eslint-disable jsx-a11y/anchor-has-content */
import { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import cn from 'classnames';
import ChevronIcon from 'components/common/icons/ChevronIcon';
import css from './Card.module.scss';

const config = {
  startIndex: 0,
  duration: {
    clip: 0.5,
    move: 0.4,
  },
  clipPath: {
    initial: 'polygon(100% 0%, 100% 50%, 100% 100%, 0% 100%, 0% 50%, 0% 0%)',
    final: {
      right: 'polygon(75% 10%, 90% 50%, 75% 90%, 10% 90%, 20% 50%, 10% 10%)',
      left: 'polygon(90% 10%, 75% 50%, 90% 90%, 30% 90%, 10% 50%, 30% 10%)'
    },
  }
}

const Gallery = (props: { items: Array<{ pic: string; link: string }> }) => {
  const [curIndex, setCurIndex] = useState(config.startIndex);
  const prevIndex = useRef(-1);
  const direction = useRef<'next' | 'prev'>('next');
  const isAnimating = useRef(false);
  const itemRefs = useRef<(HTMLAnchorElement | null)[]>([]);

  const min = 0;
  const max = props.items.length - 1;
  const btnCn = cn(css.btn, 'flex', 'items-center', 'justify-center');

  useEffect(() => {
    gsap.set(itemRefs.current[config.startIndex], { clipPath: config.clipPath.initial, display: 'block' })
  }, [])

  useEffect(() => {
    const outgoingItem = itemRefs.current[prevIndex.current];
    const upcomingItem = itemRefs.current[curIndex];

    if (!upcomingItem || !outgoingItem) return;

    gsap
      .timeline({
        onStart: (...args) => {
          upcomingItem.classList.add(css.animate);
          outgoingItem.classList.add(css.animate);
        },
        onComplete: () => {
          isAnimating.current = false;
        }
      })
      .set(upcomingItem, {
        display: 'block',
        x: direction.current === 'next' ? '-100%' : '100%',
        clipPath: direction.current === 'next' ? config.clipPath.final.right : config.clipPath.final.left
      }, 'start')
      .to(outgoingItem, {
        duration: config.duration.clip,
        ease: 'expo',
        clipPath: direction.current === 'next' ? config.clipPath.final.right : config.clipPath.final.left,
        rotation: 0.001
      }, 'start')
      .to(outgoingItem, {
        duration: config.duration.move,
        ease: 'expo.inOut',
        x: direction.current === 'next' ? '100%' : '-100%',
        rotation: 0.001,
        onComplete(...args) {
          upcomingItem.classList.remove(css.animate);
          outgoingItem.classList.remove(css.animate);
        }
      }, `start+=${config.duration.clip * 0.5}`)
      .to(upcomingItem, {
        duration: config.duration.move,
        ease: 'expo',
        x: '0%',
        rotation: 0.001,
      }, `start+=${config.duration.clip}`)
      .to(upcomingItem, {
        duration: config.duration.clip,
        ease: 'expo.inOut',
        clipPath: config.clipPath.initial,
      }, `start+=${config.duration.clip}`)
  }, [curIndex])

  const onBtnClick = (dir: 'next' | 'prev') => {
    if (isAnimating.current) return;

    setCurIndex(i => {
      prevIndex.current = i;
      direction.current = dir;
      isAnimating.current = true;

      switch (dir) {
        case 'next':
          return i + 1 > max ? min : i + 1;
        case 'prev':
          return i - 1 < 0 ? max : i - 1;
        default:
          return i;
      }
    })
  }

  const isMultiple = max >= 1;

  return (
    <div className={cn(css.gallery, 'grid', 'justify-end', 'align-center', 'col-span-2')}>
      {isMultiple ? <button type="button" className={cn(btnCn, css.btnPrev)} onClick={() => onBtnClick('prev')}>
        <ChevronIcon />
      </button> : <span />}
      <div className={cn(css.items)}>
        {props.items.map((item, index) => (
          <a
            rel="noopener noreferrer nofollow"
            target="_blank"
            href={item.link}
            className={cn(css.item, { [css.active]: curIndex === index })}
            ref={(ref) => {
              itemRefs.current[index] = ref;
            }}
            style={{ backgroundImage: `url(${item.pic})` }}
            key={item.link}
          />
        ))}
      </div>
      {isMultiple ? <button type="button" className={cn(btnCn, css.btnNext)} onClick={() => onBtnClick('next')}>
        <ChevronIcon />
      </button> : <span />}
    </div>
  );
}

export default Gallery;
