import React from 'react';

import translator from 'utils/translator';

import logo1src from 'assets/images/new-home-page/tools-integrations/salesforce-logo.svg';
import logo2src from 'assets/images/new-home-page/tools-integrations/google-analytics-logo.svg';
import logo3src from 'assets/images/new-home-page/tools-integrations/google-sheets-logo.svg';
import logo4src from 'assets/images/new-home-page/tools-integrations/zapier-logo.svg';
import logo5src from 'assets/images/new-home-page/tools-integrations/hubspot-logo.svg';

import styles from './styles.module.scss';

const _t = (path: string) => translator.text(`homePageNew.${path}`);

const toolsImagesData = [
  {
    src: logo1src,
    alt: 'salesforce',
  },
  {
    src: logo2src,
    alt: 'google-analytics',
  },
  {
    src: logo3src,
    alt: 'google-sheets',
  },
  {
    src: logo4src,
    alt: 'zapier',
  },
  {
    src: logo5src,
    alt: 'hubspot',
  },
];

function IntegrationTools() {
  return (
    <div className={`${styles.integartionTools} flex flex-col w-full items-center`}>
      <div className={styles.content}>
        <div className={styles.text}>
          <h3 className={styles.title}>{_t('integrationTools.title')}</h3>
          <p>{_t('integrationTools.text')}</p>
        </div>
        <div className={styles.toolsImages}>
          {toolsImagesData.map((item, index) => {
            return <img src={item.src} alt={item.alt} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default IntegrationTools;
