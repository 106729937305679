import el1 from 'assets/images/cards_type/el1.jpeg';
import el2 from 'assets/images/cards_type/el2.jpeg';
import el3 from 'assets/images/cards_type/el3.jpeg';
import el4 from 'assets/images/cards_type/el4.jpeg';
import el5 from 'assets/images/cards_type/el5.jpeg';
import el6 from 'assets/images/cards_type/el6.jpeg';
import reg1 from 'assets/images/cards_type/reg_1.jpg';
import reg2 from 'assets/images/cards_type/reg_2.jpg';
import info1 from 'assets/images/cards_type/info_1.jpg';
import form1 from 'assets/images/cards_type/form_1.jpg';
import form2 from 'assets/images/cards_type/form_2.jpg';
import pq1 from 'assets/images/cards_type/pq_1.jpg';
import pq2 from 'assets/images/cards_type/pq_2.jpg';
import pq3 from 'assets/images/cards_type/pq_3.jpg';
import trv1 from 'assets/images/cards_type/trv_1.jpg';
import trv2 from 'assets/images/cards_type/trv_2.jpg';
import trv3 from 'assets/images/cards_type/trv_3.jpg';
import poll1 from 'assets/images/cards_type/poll_1.jpg';
import poll2 from 'assets/images/cards_type/poll_2.jpg';
import poll3 from 'assets/images/cards_type/poll_3.jpg';
import translator from "utils/translator";

export const text = (path: string) => translator.text(`cardsFormat.${path}`);

export const logicItems: Array<{ pic: string; title: string; text: string }> = [{ pic: el1 }, { pic: el2 }, { pic: el3 }, { pic: el4 }, { pic: el5 }, { pic: el6 }].map((item, index) => ({
  ...text(`heroInfo2.items.${index}`),
  ...item,
}))

export const cardId = {
  register: 'register',
  info: 'info',
  form: 'form',
  personality: 'personality',
  trivia: 'trivia',
  poll: 'poll',
  thisOrThat: 'thisOrThat',
  trueOrFalse: 'trueOrFalse',
}

const cardLinks = new Map<string, Array<{ pic: string; link: string }>>();
cardLinks.set(cardId.register, [{pic: reg1, link: 'https://stories.sc/storycards/disney/'}, {pic: reg2, link: 'https://stories.sc/templates/atp/'}]);
cardLinks.set(cardId.info, [{pic: info1, link: 'https://stories.sc/templates/ninja/'}]);
cardLinks.set(cardId.form, [{pic: form1, link: 'https://stories.sc/templates/cnn'}, {pic: form2, link: 'https://stories.sc/templates/tiktok-for-startups/'}]);
cardLinks.set(cardId.personality, [{pic: pq1, link: 'https://stories.sc/storycards/nue0UKpTK/'}, {pic: pq2, link: 'https://stories.sc/storycards/V9Hdk3pk0/'}, {pic: pq3, link: 'https://stories.sc/templates/lacoste/'}]);
cardLinks.set(cardId.trivia, [{pic: trv1, link: 'https://stories.sc/templates/Bigi/'}, {pic: trv2, link: 'https://stories.sc/templates/samsung/'}, {pic: trv3, link: 'https://stories.sc/storycards/FSna61ebe/'}]);
cardLinks.set(cardId.poll, [{pic: poll1, link: 'https://stories.sc/templates/mcdonalds/'}, {pic: poll2, link: 'https://stories.sc/templates/storyarts/'}, {pic: poll3, link: 'https://stories.sc/templates/travel/'}]);
cardLinks.set(cardId.thisOrThat, []);
cardLinks.set(cardId.trueOrFalse, []);

export const cards = Object.values(cardId).map(id => ({
  id,
  title: text(`cardTypes.${id}.title`) as string,
  text: text(`cardTypes.${id}.text`) as string,
  items: cardLinks.get(id) ?? [],
}))
