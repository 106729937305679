import React from 'react';
import { LinkWithQuery } from 'components/common/LinkWithQuery';
import cn from 'classnames';
import translator from 'utils/translator';
import { AGENCIES_ROUTE, PUBLISHERS_ROUTE, STARTUPS_ROUTE } from 'components/constants';
import styles from './styles.module.scss';

const _t = (path: string) => translator.text(`homePageNew.${path}`);

const data = [
  {
    id: 'brand',
    content: (
      <div className={styles.contentItem}>
        <h4 className="font-semibold" dangerouslySetInnerHTML={{ __html: _t('solutions.items.0.title') }}></h4>
        <p dangerouslySetInnerHTML={{ __html: _t('solutions.items.0.text') }}></p>
        <LinkWithQuery to={STARTUPS_ROUTE} className={styles.readMore}>
          Read more...
        </LinkWithQuery>
      </div>
    ),
  },
  {
    id: 'agency',
    content: (
      <div className={styles.contentItem}>
        <h4 className="font-semibold" dangerouslySetInnerHTML={{ __html: _t('solutions.items.1.title') }}></h4>
        <p dangerouslySetInnerHTML={{ __html: _t('solutions.items.1.text') }}></p>
        <LinkWithQuery to={AGENCIES_ROUTE} className={styles.readMore}>
          Read more...
        </LinkWithQuery>
      </div>
    ),
  },
  {
    id: 'publisher',
    content: (
      <div className={styles.contentItem}>
        <h4 className="font-semibold" dangerouslySetInnerHTML={{ __html: _t('solutions.items.2.title') }}></h4>
        <p dangerouslySetInnerHTML={{ __html: _t('solutions.items.2.text') }}></p>
        <LinkWithQuery to={PUBLISHERS_ROUTE} className={styles.readMore}>
          Read more...
        </LinkWithQuery>
      </div>
    ),
  },
];

const Solutions = () => {
  return (
    <div className={`${styles.solutions} flex flex-col w-full items-center`}>
      <div className={styles.content}>
        <h3
          className={cn(styles.title, 'font-semibold')}
          dangerouslySetInnerHTML={{ __html: _t('solutions.title') }}
        ></h3>
        <div className={cn(styles.items)}>
          {data.map((item) => (
            <React.Fragment key={item.id}>{item.content}</React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Solutions;
