import React from 'react';
import { render } from 'react-snapshot';
import 'url-search-params-polyfill';

import './styles/index.css';
import './styles/base.scss';
import 'swiper/swiper.min.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// @ts-ignore
render(<App />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
