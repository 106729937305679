/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import cn from 'classnames';

import PageLayout from 'components/common/PageLayout';
import RoundCheckIcon from 'components/common/Icon/RoundCheck';

import css from './SolutionsContent.module.scss';

type Props = {
  hero: {
    img: string;
    title: string;
    subtitle: string;
  };
  items: { title: string; subtitle: string }[];
};

const SolutionsContent: React.FC<Props> = (props) => {
  return (
    <PageLayout className={css.page} contentClassName={css.pageContent}>
      <div className={cn(css.hero, 'text-center')}>
        <img src={props.hero.img} alt="" />
        <h1 className="font-semibold">{props.hero.title}</h1>
        <p>{props.hero.subtitle}</p>
      </div>
      <div className={cn(css.grid, 'grid', 'grid-cols-2')}>
        {props.items.map((item) => (
          <div className={css.gridItem}>
            <RoundCheckIcon />
            <h2 className="font-semibold">{item.title}</h2>
            <p>{item.subtitle}</p>
          </div>
        ))}
      </div>
    </PageLayout>
  );
};

export default SolutionsContent;
