import React, { useEffect, useRef, useState } from 'react';
import css from './Menu.module.scss';

type Props = {
  name: string;
  jsonVideo: {};
};
export default function MenuItem(props: Props) {
  const { name, jsonVideo } = props;
  const [isHover, setIsHover] = useState(false);
  const itemRef = useRef(null);
  const [anim, setAnim] = useState(null);
  useEffect(() => {
    if (jsonVideo) {
      // @ts-ignore
      if (window && window?.lottie !== undefined) {
        setAnim(
          // @ts-ignore
          window.lottie.loadAnimation({
            container: itemRef.current, // the dom element that will contain the animation
            loop: true,
            renderer: 'canvas', // "svg", "html"
            autoplay: false,
            animationData: jsonVideo, // the path to the animation json
          }),
        );
      }
    }
    // @ts-ignore
  }, [jsonVideo]);

  useEffect(() => {
    if (isHover) {
      // @ts-ignore
      anim.play();
    } else if (!isHover && anim !== null) {
      // @ts-ignore
      anim.stop();
    }
  }, [anim, isHover]);

  return (
    <div className={css.menuItem} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
      {/* <Lottie loop animationData={jsonVideo} play={isHover} goTo={isHover ? 0 : 0} className={css.animation} /> */}
      <div ref={itemRef} />
      <p style={{ opacity: isHover ? 0.6 : 0.3 }}>{name}</p>
    </div>
  );
}
