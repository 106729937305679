// @ts-nocheck

import Footer from "components/common/Footer";
import Header from "components/common/Header";
import EmbedStory from "components/utils/EmbedLoader";
import React from "react";
import styles from "./styles.module.scss";

function Invite() {
  const scriptSrc =
    "https://stories.sc/storycards/invite/sdk-prod.js?ver=588c47c8-09df-4eca-b69e-2599d4b82f94-1";
  const embedID = "invite";
  // ex. story-storycards/invite, embedID = 'invite'
  return (
    <>
      <Header />
      <div className={`${styles.invitePage} relative`}>
        <EmbedStory embedID={embedID} scriptSrc={scriptSrc} />
      </div>
      <Footer />
    </>
  );
}

export default Invite;
