/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import heroImg from 'assets/images/agencies_hero.jpeg';
import translator from 'utils/translator';
import SolutionsContent from 'components/common/SolutionsContent';

type Props = {};

const _t = (path: string) => translator.text(`agencies.${path}`);

const hero = {
  img: heroImg,
  title: _t('title'),
  subtitle: _t('subtitle'),
};

const Agencies: React.FC<Props> = () => <SolutionsContent hero={hero} items={_t('items')} />;

export default Agencies;
