declare global {
  interface Window {
    dataLayer: any;
  }
}

export const dataLayerEvents = {
  onHeaderLogoClick: () =>
    window.dataLayer &&
    window.dataLayer.push({
      event: "event",
      Category: "click",
      Action: "click_Logo_top",
      Label: "click_Logo_top",
    }),

  onHeaderLoginClick: () =>
    window.dataLayer &&
    window.dataLayer.push({
      event: "event",
      Category: "click",
      Action: "click_LogIn",
      Label: "click_LogIn",
    }),

  onHeaderStartCreatingClick: () =>
    window.dataLayer &&
    window.dataLayer.push({
      event: "event",
      Category: "click",
      Action: "click_Start_creating",
      Label: "click_Start_creating",
    }),
};
