import * as React from "react";

type Props = {
  color: string;
};

export default function HamburgerIcon(props: Props) {
  const { color, ...rest } = props;
  return (
    <svg
      width={27}
      height={21}
      viewBox="0 0 27 21"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <title>{"Group 7"}</title>
      <g
        stroke={color}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="square"
        strokeOpacity={0.6}
      >
        <path d="M.5.5h26M.5 10.5h26M.5 20.5h26" />
      </g>
    </svg>
  );
}
