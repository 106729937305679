import type { MutableRefObject } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import headerCss from 'components/common/Header/styles.module.scss';
import css from './styles.module.scss';

type AnimationParams = {
  containerRef: MutableRefObject<HTMLElement | null>;
  onChange: (index: number) => void;
  isMobile: boolean;
};

gsap.registerPlugin(ScrollTrigger);

export default class Animation {
  static enabled = true;

  static markers = false;

  private elements: {
    container: HTMLElement;
    tabs: HTMLDivElement;
    tabsNavSection: HTMLDivElement;
    tabsContentSection: HTMLDivElement;
    textItems: NodeListOf<HTMLDivElement>;
    contentItems: NodeListOf<HTMLDivElement>;
  } = {} as any;

  private scrollTriggers: ScrollTrigger[] = [];

  private onChange: AnimationParams['onChange'];

  private activeIndex = -1;

  constructor(params: AnimationParams) {
    if (!params.containerRef?.current) {
      throw new Error('Animation container is missing');
    }

    this.onChange = params.onChange;

    if (Animation.enabled) {
      this.setupElements(params.containerRef.current);
      if (params.isMobile) {
        this.mobileAnimation();
      } else {
        this.desktopAnimation();
      }
    }
  }

  private setupElements = (containerEl: HTMLElement) => {
    this.elements.container = containerEl;
    this.elements.tabs = containerEl.querySelector<HTMLDivElement>(`.${css.tabs}`)!;
    this.elements.tabsNavSection = containerEl.querySelector(`.${css.tabsNav}`)!;
    this.elements.tabsContentSection = containerEl.querySelector(`.${css.tabsContent}`)!;
    const { tabsNavSection: nav, tabsContentSection: content } = this.elements;
    this.elements.textItems = nav.querySelectorAll<HTMLDivElement>(`.${css.item} > div`)!;
    this.elements.contentItems = content?.querySelectorAll<HTMLDivElement>(`.${css.item}`)!;
  };

  private mobileAnimation = () => {
    const items = this.elements.tabsNavSection.children;

    const onEnter = (index: number, cbName: string) => {
      // console.info(`=== ${index} -> ${cbName}`);
      if (this.activeIndex !== index) {
        this.activeIndex = index;
        this.onChange(this.activeIndex);
        // animateIn(i);
      }
    };

    this.scrollTriggers.push(
      ...Array.from(items).map((item, index) => {
        return ScrollTrigger.create({
          trigger: item,
          start: 'bottom bottom',
          end: 'bottom center',
          onEnter: onEnter.bind(this, index, 'onEnter'),
          onEnterBack: onEnter.bind(this, index, 'onEnterBack'),
          markers: Animation.markers,
        });
      }),
    );
  };

  private desktopAnimation = () => {
    const { textItems, contentItems } = this.elements;
    const itemHeight = contentItems[0].clientHeight;

    const onEnter = (index: number, cbName: string) => {
      if (this.activeIndex !== index) {
        // show
        const nextContent = contentItems[index];
        if (nextContent) {
          nextContent.style.zIndex = '1';
          gsap.fromTo(nextContent, { opacity: 0 }, { opacity: 1, duration: 0.4, ease: 'power2.out' });
        }

        // hide
        const prevContent = contentItems[this.activeIndex];
        if (prevContent) {
          prevContent.style.zIndex = '0';
          gsap.fromTo(prevContent, { opacity: 1 }, { opacity: 0, duration: 0.3, ease: 'expo.in' });
        }

        this.activeIndex = index;
        this.onChange(this.activeIndex);
      }
    };

    // reveal items trigger
    this.scrollTriggers.push(
      ...Array.from(textItems).map((item, index) => {
        return ScrollTrigger.create({
          trigger: item,
          start: 'bottom bottom',
          end: 'bottom center',
          onEnter: onEnter.bind(this, index, 'onEnter'),
          onEnterBack: onEnter.bind(this, index, 'onEnterBack'),
          markers: Animation.markers,
        });
      }),
    );

    const halfHeaderHeight = 0 ?? (document.querySelector(`.${headerCss.header}`)?.clientHeight ?? 0) / 2;
    const centerItemOffset = (window.innerHeight - itemHeight) / 2;

    // pin trigger
    this.scrollTriggers.push(
      ScrollTrigger.create({
        trigger: this.elements.tabs,
        pin: this.elements.tabsContentSection,
        start: `top top+=${centerItemOffset + halfHeaderHeight}px`,
        end: `bottom bottom-=${centerItemOffset - halfHeaderHeight}px`,
        scrub: true,
        pinSpacing: false,
        invalidateOnRefresh: true,
        markers: Animation.markers,
      }),
    );
  };

  destroy = () => {
    this.scrollTriggers.forEach((trigger) => trigger.kill());
  };
}
