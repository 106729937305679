import React from 'react';
import cn from 'classnames';

import PageLayout from 'components/common/PageLayout';
import translator from 'utils/translator';
import NewsArticle from 'components/common/NewsArticle';

import css from './PressCenter.module.scss';

type Props = {};

type newsType = {
	website: string;
	title: string;
	description: string;
	link: string;
	date: string;
};

const _t = (path: string) => translator.text(`pressCenter.${path}`);
const news: newsType[] = _t('news');

const PressCenter: React.FC<Props> = () => {
	return (
		<PageLayout className={css.pressCenter}>
			<h1 className={cn(css.pageTitle, 'text-center', 'font-semibold')}>{_t('title')}</h1>
			<div className={css.silverBlock}>
				<div className={css.leftCol}>
					<p>Are You a Member of the Press?</p>
				</div>
				<div className={css.rightCol}>
					<p>
						Contact Our PR Team for Additional Information:{' '}
						<a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;%70%72%40%73%74%6F%72%79%2D%63%61%72%64%73%2E%63%6F%6D">
							pr@storycards.com
						</a>
					</p>
				</div>
			</div>
			<div className={css.newsList}>
				{news.map((newsItem, index) => {
					return <NewsArticle newsItem={newsItem} key={`${newsItem.title}${index}`} />;
				})}
			</div>
		</PageLayout>
	);
};

export default PressCenter;
