/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, EffectFade } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import cn from 'classnames';

import images from 'assets/images/about';
import PageLayout from 'components/common/PageLayout';
import translator from 'utils/translator';

import css from './About.module.scss';

type Props = {};

const _t = (path: string) => translator.text(`about.${path}`);

// const teamMembers = [
//   { name: "Vadim Golic", role: "CTO", pic: images.vg },
//   { name: "Viacheslav Khazan", role: "Frontend Lead", pic: images.vk },
//   { name: "Alexey Panov", role: "Full Stack Developer", pic: images.ap },
//   { name: "Viacheslav Ivantsov", role: "Full Stack Developer", pic: images.vi },
//   { name: "Aviv Yovel", role: "Team Lead", pic: images.ay },
//   { name: "Liron Yazdi", role: "Product Manager", pic: images.ly },
//   { name: "Snir Buhnik", role: "Product Designer", pic: images.master },
//   { name: "Valentyna Pohribna", role: "Full Stack Developer", pic: images.vp },
//   {
//     name: "Aleksandr Bykovsky",
//     role: "Frontend Developer",
//     pic: images.shurik,
//   },
//   { name: "Maxim Rozhnov", role: "Frontend Developer", pic: images.max },
// ];

const About: React.FC<Props> = () => {
  useEffect(() => {
    const gilLinkId = document.getElementById('gilLink');
    if (gilLinkId && gilLinkId.style) {
      gilLinkId.style.textDecoration = 'underline';
    }
  }, []);

  SwiperCore.use([Autoplay, EffectFade]);

  return (
    <PageLayout className={css.about}>
      <h1 className={cn(css.pageTitle, 'text-center', 'font-semibold')}>{_t('title')}</h1>
      <section className={css.hero}>
        <Swiper autoplay loop speed={1000} effect="fade" className={css.heroSlider}>
          {[images.hero, images.hero, images.hero].map((src, index) => {
            return (
              <SwiperSlide className={css.heroSlide} key={`${src}_${index}`}>
                <div className={css.heroImg} style={{ backgroundImage: `url(${src})` }} />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <p className={css.textTitle}>{_t('hero')}</p>
      </section>
      <section className={cn(css.company)}>
        <div className={cn(css.s1, 'grid')}>
          <div>
            <p className={cn(css.textTitle, 'font-semibold')}>{_t('company.t1')}</p>
            <p className={cn(css.textTitle, 'font-semibold')}>{_t('company.t2')}</p>
          </div>
          <div>
            <p className="">{_t('company.p1')}</p>
            <p className="">{_t('company.p2')}</p>
            <p className="">{_t('company.p3')}</p>
          </div>
        </div>
        <div className={cn(css.s2, 'grid')}>
          <p className={cn(css.textTitle, 'font-semibold')}>{_t('company.t3')}</p>
          <p>{_t('company.p4')}</p>
        </div>
      </section>
      <section className={cn(css.ceo)}>
        <div className={cn(css.grid, 'grid', 'grid-cols-2')}>
          <div className={css.col1}>
            <img className={cn(css.ceoImg)} src={images.ceo} alt="CEO: Gil Rabbi" />
            <div className={cn(css.ceoDetails)}>
              <p className={cn(css.ceoName, 'font-semibold')}>{_t('ceo.name')}</p>
              <p className={cn(css.ceoRole)}>{_t('ceo.role')}</p>
            </div>
          </div>
          <div>
            <p
              className={cn(css.ceoTitle, css.textTitle, 'font-semibold')}
              dangerouslySetInnerHTML={{ __html: _t('ceo.title') }}
              id="ceoTitle"
            ></p>
            <p>{_t('ceo.description')}</p>
            <a href="https://storycards.com/gil-rabbi" target="_blank" className={css.readMore}>
              {_t('ceo.readMore')}
            </a>
          </div>
        </div>
      </section>
      {/* <section className={css.team}>
        <p className={cn(css.teamTitle, css.textTitle, "font-semibold")}>
          {_t("team.title")}
        </p>
        <div className={cn(css.teamGrid)}>
          {teamMembers.map((member) => (
            <div
              className={cn(css.teamMember, "flex", "items-center")}
              key={member.name}
            >
              <img
                className={css.memberPic}
                src={member.pic}
                alt="Team member"
              />
              <div>
                <p className="font-semibold">{member.name}</p>
                <p>{member.role}</p>
              </div>
            </div>
          ))}
          <a
            href="mailto:careers@storycards.com"
            className={cn(css.teamMember, css.joinUs, "flex", "items-center")}
          >
            <div
              className={cn(
                css.memberPic,
                "flex",
                "items-center",
                "justify-center"
              )}
            >
              <svg
                viewBox="0 0 50 50"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g transform="translate(-767.000000, -3432.000000)">
                    <g
                      transform="translate(750.000000, 3415.000000)"
                      opacity="0.773585002"
                    >
                      <g transform="translate(-0.000000, -0.000000)">
                        <polygon points="0 0 84 0 84 84 0 84" />
                        <g
                          opacity="0.5"
                          transform="translate(21.000000, 21.000000)"
                          fill="#FFFFFF"
                        >
                          <path d="M24.5,0 L24.5,17.5 L42,17.5 L42,24.5 L24.5,24.5 L24.5,42 L17.5,42 L17.5,24.5 L0,24.5 L0,17.5 L17.5,17.5 L17.5,0 L24.5,0 Z" />
                        </g>
                        <path
                          d="M49,17.5 L48.999,35 L66.5,35 L66.5,49 L48.999,49 L49,66.5 L35,66.5 L34.999,49 L17.5,49 L17.5,35 L34.999,35 L35,17.5 L49,17.5 Z M45.5,21 L38.5,21 L38.499,38.5 L21,38.5 L21,45.5 L38.499,45.5 L38.5,63 L45.5,63 L45.499,45.5 L63,45.5 L63,38.5 L45.499,38.5 L45.5,21 Z"
                          fill="#FFFFFF"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <p className={cn(css.textTitle, "font-semibold")}>
              {_t("team.join")}
            </p>
          </a>
        </div>
      </section> */}
    </PageLayout>
  );
};

export default About;
