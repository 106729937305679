import React, { useState, useRef } from 'react';
import triangleIcon from 'assets/icons/Triangle.svg';
import { useInView } from 'utils/useInView';
import { LazyVideo } from 'components/common/LazyVideo';
import styles from './styles.module.scss';

interface Props {
  url: string;
}

function Video({ url }: Props) {
  const videoRef = useRef<null | HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const inView = useInView({ ref: videoRef, observerOptions: { rootMargin: '0px', threshold: 0.5 } });

  const playBtnClickListener = () => {
    videoRef?.current
      ?.play()
      .then(() => setIsPlaying(true))
      .catch((e) => console.error(e));
  };

  return (
    <div className={styles.videoWarpper}>
      <LazyVideo
        ref={videoRef}
        src={url}
        autoplay={inView}
        className={styles.video}
        onPlay={setIsPlaying}
        onPause={setIsPlaying}
      />
      {!isPlaying && (
        <div className={styles.playBtn} onClick={playBtnClickListener}>
          <img src={triangleIcon} alt="triangle icon" />
        </div>
      )}
    </div>
  );
}

export default Video;
