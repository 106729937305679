import React, { useRef, useEffect } from 'react';
import ScrollTrigger from 'react-scroll-trigger';

import translator from 'utils/translator';
import { blurredBg } from 'assets/images/mobile';
import styles from './styles.module.scss';

const _t = (path: string) => translator.text(`scrollingSection.${path}`);

const data = {
  scrollingTexts: [
    {
      title: _t('title'),
      text: _t('subtitle'),
      video:
        'https://player.vimeo.com/external/660945944.sd.mp4?s=78c30ec5633a57cd8ff4b7a16c9c9f0c03ca3186&profile_id=165',
      className: 'create',
    },
    {
      title: _t('items.0.title'),
      text: _t('items.0.text'),
      video:
        'https://player.vimeo.com/external/660945991.sd.mp4?s=e61fbb43c545778a29de785223f4d760cc5b67aa&profile_id=165',
      className: 'design',
    },
    {
      title: _t('items.1.title'),
      text: _t('items.1.text'),
      video:
        'https://player.vimeo.com/external/660946021.sd.mp4?s=64669c7fbc3d8113af52da9aca54baa96770d841&profile_id=165',
      className: 'embed',
    },
    {
      title: _t('items.2.title'),
      text: _t('items.2.text'),
      video:
        'https://player.vimeo.com/external/660946043.sd.mp4?s=55278b611213adf129619d2a4f4181e18c4ab5b3&profile_id=165',
      className: 'segments',
    },
  ],
};

const ScrollingSectionMobile = ({ innerRef }: any) => {
  const itemsRef = useRef<any>([]);
  const onEnterViewport = (index: number) => {
    itemsRef.current[index].play();
  };

  const onEndedVideo = (index: number) => {
    if (index === 0) {
      console.info(itemsRef.current[index]);
      itemsRef.current[index].currentTime = 2.5;
      itemsRef.current[index].play();
    } else if (index === 1) {
      itemsRef.current[index].play();
    } else if (index === 2) {
      itemsRef.current[index].currentTime = 2.18;
      itemsRef.current[index].play();
    } else if (index === 3) {
      itemsRef.current[index].currentTime = 4.12;
      itemsRef.current[index].play();
    }
  };

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, data.scrollingTexts.length);
  }, []);

  return (
    <section
      ref={innerRef}
      className={`${styles.scrollingSectionMobile} bg-top bg-no-repeat bg-contain`}
      style={{ backgroundImage: `url(${blurredBg})` }}
    >
      {data.scrollingTexts.map((item, index: number) => {
        return (
          <div className={`${styles.scrollingText} ${styles[item.className]} text-white text-center`} key={item.title}>
            <h3 className={`font-semibold`}>{item.title}</h3>
            <p className={`${styles.text}`}>{item.text}</p>
            {/* @ts-ignore */}
            <ScrollTrigger
              // @ts-ignore
              onEnter={() => onEnterViewport(index)}
              key={`scrollingItem-${index}`}
            >
              <video
                className={styles.video}
                src={item.video}
                playsInline
                muted
                ref={(el: any) => {
                  itemsRef.current[index] = el;
                }}
                onEnded={() => onEndedVideo(index)}
              />
            </ScrollTrigger>
          </div>
        );
      })}
    </section>
  );
};

export default ScrollingSectionMobile;
