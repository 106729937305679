import useResponsiveQuery from "utils/useResponsiveQuery";
import tr from "utils/translator";
import PageLayout from "components/common/PageLayout";
import imageDesktop from "assets/images/segments/segments-image.jpeg";
import imageMobile from "assets/images/segments/segments-image_mobile.png";
import styles from "./segments.module.scss";

function Analytics() {
  const isMobile = useResponsiveQuery();
  const image = isMobile ? imageMobile : imageDesktop;

  return (
    <PageLayout>
      <div className={styles.segmentsContent}>
        <div className={styles.topSection}>
          <h1 className={"font-semibold"}>{tr.text("segments.mainTitle")}</h1>
          <img src={image} alt="Storycards create segments" />
          <div className={styles.underImageTexts}>
            <h3 className={"font-semibold"}>{tr.text("segments.subTitle")}</h3>
            <p>{tr.text("segments.subTitleDescription")}</p>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default Analytics;
