import React, { useRef } from "react";
import PageLayout from "components/common/PageLayout";
import css from "./DataAndPrivacy.module.scss";
// import videoImg from "assets/images/video_image.jpg";
import tr from "utils/translator";
import InfoBox from "components/common/InfoBox";
import images from "assets/images";

interface Props {}

const DataAndPrivacy: React.FC<Props> = (props: Props) => {
  const affixBlockRef = useRef<any>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <PageLayout className={css.dataAndPrivacy}>
      <div className="flex justify-center">
        <h1 className={`${css.pageTitle} text-center`}>
          {tr.text("dataAndPrivacy.mainTitle")}
        </h1>
      </div>
      <img src={images.dataImage} alt="" className={css.image} />
      <div
        ref={wrapperRef}
        className={`${css.wrapper} flex
         justify-between
        }`}
      >
        <div
          ref={affixBlockRef}
          className={`flex flex-col ${css.leftComponent} `}
        >
          <p>{tr.text("dataAndPrivacy.affixText1")}</p>
          <p>{tr.text("dataAndPrivacy.affixText2")}</p>
        </div>
        <div className={`${css.list} flex flex-col items-end`}>
          <InfoBox
            title={tr.text("dataAndPrivacy.encryptedDataTitle")}
            text={tr.text("dataAndPrivacy.encryptedDataText")}
            className={css.infoBox}
          />

          <InfoBox
            title={tr.text("dataAndPrivacy.exportUsersTitle")}
            text={tr.text("dataAndPrivacy.exportUsersText")}
            className={css.infoBox}
          />

          <InfoBox
            title={tr.text("dataAndPrivacy.dataIntegrationTitle")}
            text={tr.text("dataAndPrivacy.dataIntegrationText")}
            className={css.infoBox}
          />
        </div>
      </div>
    </PageLayout>
  );
};

export default DataAndPrivacy;
