import React from "react";

import PageLayout from "components/common/PageLayout";
import images from "assets/images";
// import useResponsiveQuery from "utils/useResponsiveQuery";
import translator from "utils/translator";
import InfoBox from "components/common/InfoBox";

import css from "./Collaboration.module.scss";

type Props = {};

const _t = (path: string) => translator.text(`collaboration.${path}`);

const Collaboration: React.FC<Props> = () => {
  // const videoURL = {
  //   mobile: `https://player.vimeo.com/external/569348352.sd.mp4?s=62a32c78736b3d2b82af62cf8b6923bc37402ebf&profile_id=165`,
  //   desktop: `https://player.vimeo.com/external/569348352.hd.mp4?s=b70f85bc177af12b8438ea10c8ec10c56f064d6f&profile_id=169`,
  // };
  // const isMobile = useResponsiveQuery();

  return (
    <PageLayout className={css.collaboration}>
      <h1 className={css.pageTitle}>{_t("title")}</h1>

      <div className={css.imageWrap}>
        <img src={images.collaborationImage} alt="Storycards collaboration" />
      </div>
      <div className={css.pageInfo}>
        <div className={css.title}>{_t("pageInfo.title")}</div>
        <div className={css.textWrap}>
          <InfoBox className={css.infoBox} text={_t("pageInfo.text1")} />
          <InfoBox className={css.infoBox} text={_t("pageInfo.text2")} />
        </div>
      </div>
    </PageLayout>
  );
};

export default Collaboration;
