import React, { useEffect, useRef, useState } from 'react';

interface UseInViewProps {
  ref: React.RefObject<HTMLElement>;
  // set 'true' to toggle, set 'false' to set 'in view' only once
  once?: boolean;
  observerOptions?: IntersectionObserverInit;
}

export const useInView = ({ ref, once = false, observerOptions }: UseInViewProps) => {
  const [isInView, setIsInView] = useState(false);
  const observerOptionsRef = useRef(observerOptions);

  useEffect(() => {
    if (!('IntersectionObserver' in window) || !('IntersectionObserverEntry' in window)) {
      setIsInView(true);
      return;
    }

    const defaultOffset = window.innerHeight / 2;
    const options = {
      root: null,
      rootMargin: `${defaultOffset}px 0px ${defaultOffset}px 0px`,
      threshold: 0,
      ...observerOptionsRef.current,
    };

    const observer = new IntersectionObserver(([entry]) => {
      setIsInView((prev) => {
        if (once && prev) return prev;
        return entry.isIntersecting;
      });
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, once]);

  return isInView;
};
