import { MutableRefObject, useEffect } from 'react';

/**
 * @param ref {Object}
 * @param ref.current {Node}
 * @param handler {Function}
 * @param params {Object}
 * @param params.extraDoc {Object|Array<Object>}
 */
export default function useClickOutside(
  ref: MutableRefObject<any>,
  handler?: (event: Event) => void,
  params: { extraDoc?: Document | null } = {},
) {
  useEffect(() => {
    const listener = (event: Event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler?.(event);
    };

    const docs = [document];

    if (params.extraDoc) {
      docs.push(params.extraDoc);
    }

    docs.forEach((doc) => {
      doc.addEventListener('mousedown', listener);
      doc.addEventListener('touchstart', listener);
    });

    return () => {
      docs.forEach((doc) => {
        doc.removeEventListener('mousedown', listener);
        doc.removeEventListener('touchstart', listener);
      });
    };
  }, [ref, handler, params.extraDoc]);
}
