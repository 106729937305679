import React from "react";
import Icon from "../../common/Icon";
import translator from "utils/translator";
import css from "./styles.module.scss";
const _t = (path: string) => translator.text(`pageNotFound.${path}`);

export default function PageNotFound404(props: any) {
  const onClickBackButton = () => {
    props.history.push("/");
  };
  return (
    <div className={css.pageNotFound}>
      <div>
        <Icon type="404" className={css.svg} />
        <h1>{_t("pageNotFound")}</h1>
      </div>
      <button className={css.homePageButton} onClick={onClickBackButton}>
        {_t("backToHome")}
      </button>
    </div>
  );
}
