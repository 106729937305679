import React from 'react';
import cn from 'classnames';
import bigi from 'assets/images/clients/bigl-black.svg';
import eurovision from 'assets/images/clients/eurovision-black.svg';
import n12 from 'assets/images/clients/n12-black.svg';
import mccann from 'assets/images/clients/mccann-logo-black.svg';
import sport5 from 'assets/images/clients/sport5-black.svg';
import ynet from 'assets/images/clients/ynet-black.svg';
import mako from 'assets/images/clients/mako-black.svg';
import aman from 'assets/images/clients/aman-black.svg';
import israelBidur from 'assets/images/clients/israel-bidur-black.svg';
import israelHayom from 'assets/images/clients/israel-hayom-black.svg';
import nuis from 'assets/images/clients/nuis-black.svg';
import reshet13 from 'assets/images/clients/reshet13-black.svg';
import walla from 'assets/images/clients/walla-black.svg';
import kan11 from 'assets/images/clients/kan11-black.svg';

import styles from './styles.module.scss';

const clients = [
  { src: eurovision, href: 'https://eurovision.tv', id: 'eurovision' },
  { src: mccann, href: 'https://mccann.co.il', id: 'mccann' },
  { src: aman, href: '', id: 'aman' },
  { src: kan11, href: '', id: 'kan11' },
  { src: ynet, href: 'https://ynet.co.il', id: 'ynet' },
  { src: mako, href: 'http://mako.co.il', id: 'mako' },
  { src: n12, href: 'https://n12.co.il', id: 'n12' },
  { src: walla, href: '', id: 'walla' },
  { src: reshet13, href: '', id: 'reshet13' },
  { src: israelBidur, href: '', id: 'israel-bidur' },
  { src: israelHayom, href: '', id: 'israel-hayom' },
  { src: nuis, href: '', id: 'nuis' },
  { src: sport5, href: 'https://sport5.co.il', id: 'sport5' },
  { src: bigi, href: '', id: 'bigi' },
];

const ClientsSection = () => {
  return (
    <section className={cn(styles.clients, 'text-center')}>
      <div className={cn(styles.listWrapper, 'flex')}>
        <div className={cn(styles.list, 'flex', 'flex-shrink-0')}>
          <div className={cn(styles.listItems, 'grid', 'items-center', 'justify-items-start', 'flex-shrink-0')}>
            {clients.map((client) => (
              <img src={client.src} alt={client.id} key={`l1-client-logo-${client.id}`} loading="lazy" />
            ))}
          </div>
          <div className={cn(styles.listItems, 'grid', 'items-center', 'justify-items-start', 'flex-shrink-0')}>
            {clients.map((client) => (
              <img src={client.src} alt={client.id} key={`l2-client-logo-${client.id}`} loading="lazy" />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientsSection;
