import React from "react";
import css from "./NewsArticle.module.scss";

type Props = {
  newsItem: {
    website: string;
    title: string;
    description: string;
    link: string;
  };
};

const NewsArticle: React.FC<Props> = ({
  newsItem: { website, title, description, link },
}) => {
  return (
    <div className={`${css.newsArticleWrapper}`}>
      <div className={css.title}>{website}</div>

      <div className={css.site}>
        <a href={link}>{title}</a>
      </div>
      <div
        className={css.description}
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
    </div>
  );
};

export default NewsArticle;
