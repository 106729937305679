import { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-scroll';
import cn from 'classnames';

import _ from 'lodash';
import useResponsiveQuery from 'utils/useResponsiveQuery';
import info2Json from 'assets/images/cards_type/info3.json';
import leadsFormJson from 'assets/images/cards_type/Leads_form3.json';
import personalityTestJson from 'assets/images/cards_type/Personality_test3.json';
import pollJson from 'assets/images/cards_type/Poll3.json';
import registrationJson from 'assets/images/cards_type/Registration3.json';
import thisOrThatJson from 'assets/images/cards_type/This_or_that3.json';
import triviaJson from 'assets/images/cards_type/Trivia3.json';
import trueOrFalseJson from 'assets/images/cards_type/True_or_false3.json';

import MenuItem from './MenuItem';
import css from './Menu.module.scss';
import { cardId } from '../data';

export default function Menu() {
  const [isMobileMinifyMenu, setMobileIsMinifyMenu] = useState(false);
  const [isMinifyMenu, setIsMinifyMenu] = useState(false);
  const [isHideMenu, setIsHideMenu] = useState(false);
  const isMobile = useResponsiveQuery();

  const scrollHeight = useRef(1);

  const menuItems = [
    { name: 'Trivia', jsonVideo: triviaJson, titleScroll: cardId.trivia },
    { name: 'Personality Test', jsonVideo: personalityTestJson, titleScroll: cardId.personality },
    { name: 'Poll', jsonVideo: pollJson, titleScroll: cardId.poll },
    { name: 'Leads Form', jsonVideo: leadsFormJson, titleScroll: cardId.form },
    { name: 'Registration', jsonVideo: registrationJson, titleScroll: cardId.register },
    { name: 'This or That', jsonVideo: thisOrThatJson, titleScroll: cardId.thisOrThat },
    { name: 'True or False', jsonVideo: trueOrFalseJson, titleScroll: cardId.trueOrFalse },
    { name: 'Info', jsonVideo: info2Json, titleScroll: cardId.info },
  ];

  useEffect(() => {
    const title: any = document.getElementById('title');
    const spaceFromTop = title?.getBoundingClientRect().top - title?.getBoundingClientRect().height + 100;
    let lastScrollTop = 0;

    const onScroll = () => {
      if (window.scrollY > spaceFromTop - 150) {
        if (!isMobile) {
          setIsMinifyMenu(true);
        }
      } else {
        if (!isMobile) {
          setIsMinifyMenu(false);
        }
      }

      // When scrolling down or top
      let st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > lastScrollTop) {
        setMobileIsMinifyMenu(true);
      } else {
        setMobileIsMinifyMenu(false);
      }

      // Hide desktop menu when scroll more than 130px and scroll direction bottom
      if (window.scrollY > spaceFromTop && st > lastScrollTop) {
        setIsHideMenu(true);
      } else {
        setIsHideMenu(false);
      }
      lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    };

    const onWindowResize = () => {
      scrollHeight.current = document.body.scrollHeight;
    };

    window.addEventListener('scroll', _.throttle(onScroll, 300), false);
    window.addEventListener('resize', onWindowResize);
    onWindowResize();

    return () => {
      window.removeEventListener('scroll', onScroll, false);
      window.removeEventListener('resize', onWindowResize);
    };
  }, [isMobile]);

  return (
    <>
      <div className={cn(css.items, css.fullMenu, isMinifyMenu ? css.minMenu : '', isHideMenu ? css.hidden : '')}>
        <>
          {_.map(menuItems, (item, index) => {
            return (
              <div className={css.item} key={`title-${index}`}>
                <Link to={`${item.titleScroll}`} smooth={true} duration={750} offset={-80} isDynamic>
                  <MenuItem name={item.name} jsonVideo={item.jsonVideo} />
                </Link>
              </div>
            );
          })}
        </>
      </div>

      {/***  Mobile menu ***/}
      {isMobile && (
        <div className={cn(css.items, css.minifyMenuMobile, isMobileMinifyMenu ? css.minify : '')}>
          <Swiper slidesPerView="auto">
            {_.map(menuItems, (item, index) => {
              return (
                <SwiperSlide className={css.fullSwiperSlide} key={item.name}>
                  <div className={css.item} key={`title-${index}`}>
                    <Link
                      to={`${item.titleScroll}`}
                      smooth={true}
                      duration={750}
                      offset={-80}
                      isDynamic
                      onClick={() => {
                        setMobileIsMinifyMenu(false);
                      }}
                    >
                      <MenuItem name={item.name} jsonVideo={item.jsonVideo} />
                    </Link>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      )}
    </>
  );
}
