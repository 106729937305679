import React from 'react';
import cn from 'classnames';

import PageLayout from 'components/common/PageLayout';

import css from '../PrivacyPolicy/PrivacyPolicy.module.scss';

const DPA: React.FC<{}> = () => {
	return (
		<PageLayout contentClassName={css.root}>
			<h1 className={cn(css.t1, 'text-center', 'font-semibold')}>
				Data Processing Agreement
				<br />
				For User
			</h1>
			<section>
				<p>Last updated: September 01, 2021</p>
				<p>
					This Data Processing Agreement (&ldquo;<strong>DPA&rdquo;</strong>) has been entered by and between
					Storycards ltd. (hereinafter <strong>"Storycards"</strong>, <strong>"Our"</strong>,{' '}
					<strong>"We"</strong>,<strong> "Company"</strong>) and You (<strong>"You"</strong>,{' '}
					<strong>"Your"</strong> <strong>"User"</strong>) as a user of Our Platform and Services. This DPA
					forms part of Our Terms of Use and Privacy Policy (collectively, "<strong>Agreement</strong>").
				</p>
				<p>
					Storycards and the User are referred to individually as &ldquo;<strong>Party</strong>&rdquo; and
					collectively as &ldquo;<strong>Parties</strong>&rdquo;.
				</p>
				<p>
					To the extent Visitor`s Personal Data is processed by the Company on your behalf you acknowledge and
					agree that We will process such Personal Data as necessary to provide you with the Services and as
					further detailed herein, and by using Our Platform and Services, you instruct us to process such
					Visitors Personal Data on your behalf under this DPA.
				</p>
				<p>
					Any questions regarding this DPA should be addressed to Our Data Protection Officer at&nbsp;
					<a href="mailto:privacy@storycards.com">privacy@storycards.com</a>.
				</p>

				<br />

				<ol className={css.withCounters}>
					<li>
						<h2 className={css.t2}>1. Background and objective</h2>

						{/* 1.1 1.2 1.3 */}
						<ol>
							<li>
								The Parties entered into a contractual relationship under the Agreement and this DPA is
								an extract to. Within the scope of its assignment, The User Will/May be able to collect
								and process Personal Data of Visitors, and We will/may gain access to and process
								Personal Data collected by You.
							</li>
							<li>
								The objective of the DPA is to comply with the requirements in the Data Protection
								Legislation for a written agreement between the Parties.
							</li>
							<li>
								This DPA will apply ONLY if Storycards processes Personal Data made available by the
								User and that Data protection laws applies to the processing of such data.
							</li>
							{/*<li>
                <p>Test nested</p>
                 1.3.1 1.3.2 1.3.3
                <ol>
                  <li>The Parties entered into a contractual relationship under the Agreement and this DPA is an extract to. Within the scope of its assignment, The User Will/May be able to collect and process Personal Data of Visitors, and We will/may gain access to and process Personal Data collected by You.</li>
                  <li>The objective of the DPA is to comply with the requirements in the Data Protection Legislation for a written agreement between the Parties.</li>
                  <li>This DPA will apply ONLY if Storycards processes Personal Data made available by the User and that Data protection laws applies to the processing of such data.</li>
                </ol>
              </li>
              <li>
                <p>Test letters</p>

                <ol className={css.letters}>
                  <li>Lorem ipsum.</li>
                  <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias dolor doloribus non? Consequuntur dignissimos eius sequi voluptate? Delectus incidunt, placeat.</li>
                  <li>Lorem ipsum.</li>
                  <li>Lorem ipsum.</li>
                </ol>
              </li>*/}
						</ol>
					</li>

					<li>
						<h2 className={css.t2}>2. Definitions</h2>

						<ol>
							<li>
								<p>
									The terms used in this DPA shall have the same meaning as assigned to them below and
									in the Data Protection Legislation, which inter alia imply that:
								</p>

								<ol className={css.letters}>
									<li>
										<strong>Personal Data</strong>&nbsp;–&nbsp;means any information that, directly
										or indirectly, can identify a living natural person;{' '}
										<strong>Visitors personal data</strong> shall mean the personal data for your
										visitors (as such determined in the Agreement).
									</li>
									<li>
										The term <strong>processing</strong> means any operation or set of operations
										performed with regard to personal data, whether or not performed by automated
										means, for example, collection, recording, organization, storage, adaptation or
										alteration, retrieval, gathering, use, disclosure by transmission, dissemination
										or otherwise making information available, alignment or combination, blocking,
										erasure or destruction;
									</li>
									<li>
										<strong>Data controller</strong> or <strong>Controller</strong> mean anyone who
										alone or jointly with others determines the purposes and means of the processing
										of personal data;
									</li>
									<li>
										<strong>Data processor</strong> or <strong>Processor</strong> shall mean anyone
										who processes personal data on behalf of the data controller;
									</li>
									<li>
										The term <strong>sub-processor</strong> means a sub-contractor that is engaged
										by Storycards as Processor. The sub-processor processes personal data on behalf
										of Controller in accordance with the sub-processor’s obligation to provide its
										services to Processor;
									</li>
									<li>
										The term{' '}
										<strong>standard data protection clauses adopted by the EU-Commission</strong>{' '}
										means standard contractual clauses regulating the transfer of personal data to
										third countries and that have been adopted by the EU Commission in accordance
										with Commission Decision C(2010)593 of 5 February 2010 or corresponding decision
										replacing such decision;
									</li>
									<li>
										<strong>Data Protection Laws</strong> or{' '}
										<strong>Data Protection Legislation</strong> means EU Data Protection Laws and,
										to the extent applicable, the data protection or privacy laws of any other
										country; <strong>EU Data Protection Laws</strong> means EU Directive 95/46/EC,
										as transposed into domestic legislation of each Member State and as amended,
										replaced or superseded from time to time, including by the GDPR and laws
										implementing or supplementing the GDPR; <strong>GDPR</strong> means EU General
										Data Protection Regulation 2016/679;
									</li>
									<li>
										<strong>EEA</strong> means the European Economic Area;
									</li>
									<li>
										Without derogating from the above, The terms, <strong>Commission</strong>,{' '}
										<strong>Controller</strong>, <strong>Data Subject</strong>,{' '}
										<strong>Member State</strong>, <strong>Personal Data</strong>,{' '}
										<strong>Personal Data Breach</strong>, <strong>Processing</strong> and{' '}
										<strong>Supervisory Authority</strong> shall have the same meaning as in the
										GDPR, and their cognate terms shall be construed accordingly.
									</li>
								</ol>
							</li>
						</ol>
					</li>

					<li>
						<h2 className={css.t2}>3. Roles and responsibilities</h2>

						<ol>
							<li>Notwithstanding anything to the contrary in this DPA, it is hereby clarified that:</li>
							<li>
								With regard to the Processing of Visitors personal data on your behalf, (i) you are the
								Controller and We are the Processor; and (ii) for the purposes of the CCPA (if
								applicable), you are the &ldquo;Business&rdquo; and We are the &ldquo;Service
								Provider&rdquo; (as such terms are defined in the CCPA).&nbsp;
							</li>
							<li>
								<p>
									We will process Visitor's personal data in order to provide the Services in
									accordance with the Agreement and this DPA. The nature and purposes of the
									Processing are as set in the Privacy Policy.
								</p>

								<ol>
									<li>
										<strong>Processing by the User</strong>. When using Our Service, you shall:
									</li>

									<ol className={css.letters}>
										<li>
											Ensure that your submission of Personal Data to Our Platform, your
											instructions for the Processing of Visitor's personal data, and your
											processing of Visitor's personal data in your use of the Services will
											comply with Data Protection Laws.
										</li>
										<li>
											Establish and have any and all required consents, legal bases and
											authorizations in order to collect, use and otherwise process and transfer
											to Storycards the Visitor's personal data, and to authorize the Processing,
											and for Our Processing activities on your behalf, including the pursuit of
											&lsquo;business purposes&rsquo; as defined under the CCPA.
										</li>
										<li>
											Have sole responsibility for the accuracy, quality, and legality of
											Visitor's personal data and the means by which it was obtained.
										</li>
										<li>
											Be solely responsible for any transfer of visitor's personal data by you (or
											any other person operating on your behalf) to any platform other than
											Storycards, or any other third party.
										</li>
									</ol>
								</ol>
							</li>
						</ol>
					</li>

					<li>
						<h2 className={css.t2}>4. Undertaking and instruction</h2>

						<ol>
							<li>
								Each party undertakes to process the personal data that it has access to under the
								Agreement on behalf of the other party, for the purpose of fulfilling the Agreement and
								during the term of the Agreement. Both parties further undertake:
							</li>

							<ol className={css.letters}>
								<li>
									To process the personal data in accordance with the Data Protection Legislation, and
									the Agreement.&nbsp;
								</li>
								<li>
									To keep the Personal Data confidential and not to disclose the Personal Data to any
									third parties or in any other way use the personal data in contradiction with the
									Agreement and this DPA. Parties shall also ensure that persons authorized to process
									the Personal Data have committed themselves to confidentiality or are under an
									appropriate statutory obligation of confidentiality;
								</li>
								<li>
									Each party shall maintain a publicly accessible privacy policy that is available via
									a prominent link that satisfies the transparency disclosure requirements of Data
									Protection Laws. Each party warrants and represents that it has provided data
									subjects with appropriate transparency regarding data collection and use and all
									required notices and obtained any and all consents or permissions necessary under
									Data Protection Laws.&nbsp;
								</li>
								<li>
									To assist Other Party, taking into account the nature of the processing, by
									implementing appropriate technical and organizational measures, insofar as this is
									possible, for the fulfilment of parties obligations to respond to and to fulfil
									requests from data subjects exercising their rights laid down in Chapter III of the
									GDPR; and
								</li>
								<li>
									To assist each other in ensuring compliance with the obligations pursuant to
									Articles 32 to 36 of the GDPR (implement security measures, manage personal data
									breaches, conduct data privacy impact assessments and participate in prior
									consultations with the supervisory authority) taking into account the nature of the
									processing and the information available to Parties.&nbsp;
								</li>
								<li>
									To respond to requests to exercise Data Subject rights under the Data Protection
									Laws.
								</li>
							</ol>
						</ol>
					</li>

					<li>
						<h2 className={css.t2}>5. Transfer of personal data</h2>
						<p>
							Either party may Transfer Personal Data out of the EU/EEA if it complies with the provisions
							on the transfer of Personal Data to Third-countries in the Data Protection laws (such as
							transfer of Data to Jurisdictions that have adequate data protection laws).
						</p>
						<p>
							If personal data processed under this Agreement is transferred from a country within the
							European Economic Area to a country outside the European Economic Area, the Parties shall
							ensure that the personal data are adequately protected. To achieve this, the Parties shall,
							unless agreed otherwise, rely on EU approved standard contractual clauses for the transfer
							of personal data.
						</p>
					</li>

					<li>
						<h2 className={css.t2}>6. Information security</h2>
						<p>
							The parties shall implement all appropriate technical and organizational measures necessary
							in order to ensure a level of security, as required pursuant to the Data Protection
							Legislation (Article 32 of the GDPR (32 &sect; Data Protection Act (523/1999)) and other
							measures necessary in order for the parties to comply with the security requirements set out
							in the Agreement).
						</p>
						<p>
							If a party makes changes that could affect the protection of personal data, it shall inform
							the other party of this well in advance before such changes are implemented.
						</p>
						<p>
							<strong>Data Breach</strong>. In the event of a data breach or any potential violation of
							information security, The Parties shall notify Each other without delay after becoming aware
							of the infringement of information security of Personal Data or any other violation of Data
							Protection Legislation or this DPA. As a part of the notification, You must inform
							Storycards without delay and in writing all the necessary information about the disturbance
							and the related measures, especially:
						</p>

						<ol className={css.letters}>
							<li>
								a description of the nature of the infringement of information security, including the
								information of registered groups and estimated amount of registered persons affected by
								the infringement along with the information required by Data Protection Legislation
							</li>
							<li>
								necessary information regarding the statutory obligations and fulfillment of the
								contractual obligations of Controller. These obligations shall be based, inter alia,
								Data Protection Legislation, agreements made with third parties and/or a request, a
								guidance and/or a ruling made by the supervisory authority or a tribunal
							</li>
							<li>
								necessary information for preventing similar infringements of the information security
								and information required for the notifications made for the Visitor, registered persons,
								and possible third parties.
							</li>
						</ol>
						<p>
							It is hereby clarified that any notice to be given to Visitors and third parties is the full
							responsibility of the User and he undertakes to do so without any delay, and in accordance
							with Data Protection Legislation.
						</p>
					</li>

					<li>
						<h2 className={css.t2}>7. Audit</h2>

						<ol>
							<li>
								You undertake to facilitate and participate in audits, including inspections, carried
								out by Storycards or governmental authority or by a third party authorized by
								Storycards.
							</li>
							<li>
								The parties shall immediately inform and consult with the other in the event that a
								supervisory authority initiates or takes any action in relation to the processing of
								personal data under the Agreement or the DPA.
							</li>
						</ol>
					</li>

					<li>
						<h2 className={css.t2}>8. Data Protection Impact Assessment and Prior Consultation</h2>
						<p>
							Both Parties shall provide reasonable assistance to each other with any data protection
							impact assessments, and prior consultations with Supervising Authorities or other competent
							data privacy authorities, which Company reasonably considers to be required by article 35 or
							36 of the GDPR or equivalent provisions of any other Data Protection Law.
						</p>
					</li>

					<li>
						<h2 className={css.t2}>9. Engaging sub-processors</h2>

						<ol>
							<li>
								We may engage and/or replace a sub-processor for the performance of Our processing for
								Visitor`s personal data and Collected Data, as defined in the privacy policy, under the
								DPA.
							</li>
						</ol>
					</li>

					<li>
						<h2 className={css.t2}>10. Damages and compensation</h2>

						<p>
							You shall, without limitation, hold harmless and indemnify Storycards in the event of damage
							that is attributable to the User`s processing of personal data in breach of the DPA or the
							Data Protection Legislation. For the avoidance of doubt, administrative fines are imposed on
							the Party in breach of its obligations and, in consequence, neither party will bear the
							other Party&rsquo;s administrative fines.
						</p>
					</li>

					<li>
						<h2 className={css.t2}>11. Order of validity of contract documents</h2>
						<p>
							This DPA is an irremovable part of the Agreement. In any event of a contradiction between
							this DPA and the Agreement, the provisions of this DPA shall govern solely with respect to
							the Processing of Visitors Personal Data.
						</p>
					</li>

					<li>
						<h2 className={css.t2}>12. Miscellaneous</h2>

						<ol>
							<li>The DPA is effective for as long as You are using Our Service.</li>
							<li>
								In the event that You are in breach of your obligations under this DPA or Data
								Protection Legislation, and fails to remedy the deficiency within thirty (30) days after
								being notified of the breach, or within the time period agreed between the Parties, We
								have the right to terminate the Agreement with immediate effect or the longer period of
								notice notified by us.
							</li>
							<li>
								<strong>Costs</strong>. Subject to applicable Data Protection Laws, to the extent any
								assistance described in this DPA entails material costs or expenses to Storycards, the
								parties shall first come to an agreement on Your reimbursement to Storycards of such
								costs and expenses.
							</li>
							<li>
								<strong>Deletion of Personal data. </strong>Upon termination of your use of the Services
								or your written request submitted through one of the methods detailed in the Privacy
								Policy, We shall delete Your Personal Data and related Visitor`s Personal Data as soon
								as reasonably practicable and according to the Agreement and applicable laws.
								<p>
									Notwithstanding the foregoing, We may retain Personal Data and Visitors Personal
									data (or a portion of it), if required under the Agreement or by applicable law or
									regulation (including applicable Data Protection Laws); provided such Personal data
									remains protected in accordance with the terms of this DPA and applicable Data
									Protection Laws.
								</p>
							</li>
							<li>
								For the avoidance of doubt and to the extent allowed by applicable law, all liability
								under this DPA, including limitations thereof, will be governed by the relevant
								provisions of Our Terms of Use.
							</li>
							<li>
								You acknowledge and agree that We may amend this DPA as may be required from
								time-to-time, by posting the relevant amended and DPA on Our website, available at{' '}
								<a href="https://storycards.com">https://storycards.com</a> and any amendments to the
								DPA are effective as of the date of posting. Your continued use of the Services after
								the amended DPA is posted constitutes your agreement to, and acceptance of, the amended
								DPA.
							</li>
							<li>
								If any provision of this DPA deemed by a court of competent jurisdiction to be invalid,
								unlawful, void, or for any reason unenforceable, then such provision shall be deemed
								severable and will not affect the validity and enforceability of the remaining
								provisions.
							</li>
						</ol>
					</li>

					<li>
						<h2 className={css.t2}>13. Governing law and Dispute resolution</h2>

						<ol>
							<li>The DPA shall be governed by and construed in accordance with law of Israel.</li>
							<li>
								Disputes regarding the interpretation and application of the DPA shall be settled in
								accordance with the provisions in the Agreement regarding dispute resolution.
							</li>
						</ol>
					</li>
				</ol>
			</section>
		</PageLayout>
	);
};

export default DPA;
