import React, { useEffect, useRef, forwardRef } from 'react';
import { useInView } from 'utils/useInView';

interface LazyVideoProps {
  src: string;
  autoplay: boolean;
  poster?: string;
  // Set 'true' to load the video only once, set 'false' to load/unload each time it enters/leaves the viewport
  once?: boolean;
  className?: string;
  onPlay?: (isPlaying: boolean) => void;
  onPause?: (isPlaying: boolean) => void;
}

export const LazyVideo = forwardRef<HTMLVideoElement, LazyVideoProps>(
  ({ src, autoplay, poster, once, className, onPlay, onPause }, ref) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const inView = useInView({ ref: videoRef });
    const onPlayRef = useRef(onPlay);
    const onPauseRef = useRef(onPause);

    useEffect(() => {
      const video = videoRef.current;
      if (!video) return;

      if (inView) {
        if (!video.src) {
          video.src = src;
          video.preload = 'metadata';
        }
        if (autoplay) {
          video
            .play()
            .then(() => onPlayRef.current?.(true))
            .catch((e) => console.error(e));
        }
      } else {
        video.pause();
        video.currentTime = 0;
        onPauseRef.current?.(false);
        if (!once) video.removeAttribute('src');
      }
    }, [inView, src, autoplay, once]);

    return (
      <video
        ref={(_ref) => {
          videoRef.current = _ref;
          if (ref) {
            if (typeof ref === 'function') ref(_ref);
            else ref.current = _ref;
          }
        }}
        title="demonstation content"
        muted
        playsInline
        loop
        preload="none"
        poster={poster}
        className={className}
      />
    );
  },
);
