import React, { useState, useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import styles from './styles.module.scss';
import useResponsiveQuery from 'utils/useResponsiveQuery';

import { ScrollTrigger } from 'gsap/ScrollTrigger';

import cardsImageSrc from 'assets/images/new-home-page/cards.png';
import integrationsImageSrc from 'assets/images/new-home-page/integrations.png';

function HeadlineSection({ innerRef }: any) {
  gsap.registerPlugin(ScrollTrigger);
  const isMobile = useResponsiveQuery();
  const videoRef = useRef<HTMLVideoElement>(document.createElement('video'));
  const [mute, setMute] = useState(true);
  const titleContent = 'The leading no-code solution for enterprise companies';
  const integrationsRef = useRef<HTMLDivElement | null>(null);
  const cardsRef = useRef<HTMLDivElement | null>(null);

  const paragraphContent =
    'We empower enterprise teams—marketing, product, data, and more—to effortlessly create exceptional digital products. Our technology streamlines workflows and fosters seamless collaboration across all departments in the organization.';
  const title = 'No-code platform for engaging data collection';
  const subtitle =
    'Storycards is a no-code platform empowering enterprises to create premium data collection products on a fully visual canvas, powered by AI and no-code technology';
  const desktopVideoLink =
    'https://player.vimeo.com/progressive_redirect/playback/1037488241/rendition/1080p/file.mp4?loc=external&signature=14c4a2013008887b28be2a9dee8a34fc0765e773a8d252c707c1e385c58d0ac4';
  const mobileVideoLink =
    'https://player.vimeo.com/progressive_redirect/playback/1037488241/rendition/540p/file.mp4?loc=external&signature=5f4959471f85c6f0ec3fc5d9230734a356f89e30e17e26b59fc768bb4d5b7afb';
  const videoSRC = isMobile ? mobileVideoLink : desktopVideoLink;

  useEffect(() => {
    const [leftItem, rightItem] = [cardsRef.current, integrationsRef.current];
    const toggleActions = 'play none none reverse';
    const [enter, leave] = [
      { opacity: 1, y: 0 },
      { opacity: 0, y: 50 },
    ];
    gsap.set([leftItem, rightItem], leave);

    const tl1 = gsap.to(leftItem, {
      scrollTrigger: {
        trigger: leftItem,
        start: 'center bottom',
        end: 'bottom top',
        toggleActions,
      },
      ...enter,
      duration: 1,
      ease: 'power3.inOut',
    });

    const tl2 = gsap.to(rightItem, {
      scrollTrigger: {
        trigger: rightItem,
        start: 'bottom bottom',
        end: 'bottom top',
        toggleActions,
      },
      ...enter,
      duration: 0.75,
      ease: 'power1.inOut',
    });

    return () => {
      tl1.kill();
      tl2.kill();
    };
  }, []);

  return (
    <section ref={innerRef} className={`${styles.headlineSection} flex flex-col w-full items-center`}>
      <div className={styles.textTop}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.subtitleWrapper}>
          <div className={styles.subtitle}>{subtitle}</div>
          <a href="https://signup.storycards.com/new" className={styles.packagesBtn}>
            Get Started →
          </a>
        </div>
      </div>
      <div className={`${styles.videoContainer}`}>
        <video
          playsInline
          autoPlay
          ref={videoRef}
          loop
          muted={mute}
          disableRemotePlayback
          src={videoSRC}
          className={mute ? styles.videoMuteCursor : styles.videoUnmuteCursor}
          onClick={() => setMute(!mute)}
        />
        <div className={styles.cardsWrapper} ref={cardsRef}>
          <img src={cardsImageSrc} alt="cards" />
        </div>
        <div className={styles.integarationsWrapper} ref={integrationsRef}>
          <img src={integrationsImageSrc} alt="integrations" />
        </div>
      </div>

      <div
        className={`${styles.content} flex flex-col md:flex-row items-center md:items-start text-center md:text-left`}
      >
        <h3>{titleContent}</h3>
        <p>{paragraphContent}</p>
      </div>
    </section>
  );
}

export default HeadlineSection;
