import React, { useState } from 'react';
import classNames from 'classnames';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import translator from 'utils/translator';

import Video from './Video';

import styles from './styles.module.scss';

const _t = (path: string) => translator.text(`homePageNew.${path}`);

type ActiveTab = 'design' | 'content';

const tabsBtns: Array<{ title: string; active: ActiveTab }> = [
  {
    title: _t('designContent.tabsTitles.0'),
    active: 'design',
  },
  {
    title: _t('designContent.tabsTitles.1'),
    active: 'content',
  },
];

const tabsContent: Array<{ title: string; text: string; videoLink: string }> = [
  {
    title: _t('designContent.tabsContent.0.title'),
    text: _t('designContent.tabsContent.0.text'),
    videoLink:
      'https://player.vimeo.com/external/660359266.hd.mp4?s=181e2d4d58c43142b701c9803ed57f33cff6777b&profile_id=175',
  },
  {
    title: _t('designContent.tabsContent.1.title'),
    text: _t('designContent.tabsContent.1.text'),
    videoLink:
      'https://player.vimeo.com/external/660359266.hd.mp4?s=181e2d4d58c43142b701c9803ed57f33cff6777b&profile_id=175',
  },
];

function DesignContent() {
  const [activeTab, setActiveTab] = useState<ActiveTab>('design');

  const renderTab = ({ title, text, videoLink }: { title: string; text: string; videoLink: string }) => {
    return (
      <div className={styles.TabWrapper}>
        <div className={classNames(styles.tabContent)}>
          <div className={styles.text}>
            <h2 className={styles.title}>{title}</h2>
            <p>{text}</p>
          </div>
          <Video url={videoLink} />
        </div>
      </div>
    );
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'design':
        return renderTab({
          title: tabsContent[0].title,
          text: tabsContent[0].text,
          videoLink: tabsContent[0].videoLink,
        });
      case 'content':
        return renderTab({
          title: tabsContent[1].title,
          text: tabsContent[1].text,
          videoLink: tabsContent[1].videoLink,
        });
      default:
        break;
    }
  };

  return (
    <div className={classNames(styles.designContent, 'flex flex-col w-full items-center')}>
      <div className={classNames(styles.content, 'flex flex-col items-center')}>
        <div
          className={classNames(
            styles.tabsBtns,
            activeTab === 'design' && styles.left,
            activeTab === 'content' && styles.right,
          )}
        >
          {tabsBtns.map((item, index) => {
            return (
              <div
                key={index}
                className={classNames(styles.tabBtn, item.active === activeTab && styles.active)}
                onClick={() => {
                  setActiveTab(item.active);
                }}
              >
                {item.title}
              </div>
            );
          })}
        </div>
        <div className={styles.tabsContent}>
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={activeTab}
              timeout={200}
              classNames={{
                enter: styles.TabWrapperEnter,
                enterActive: styles.TabWrapperEnterActive,
                enterDone: styles.TabWrapperEnterDone,
                exit: styles.TabWrapperExit,
                exitActive: styles.TabWrapperExitActive,
                exitDone: styles.TabWrapperExitDone,
              }}
              appear
              mountOnEnter
              unmountOnExit
            >
              {renderContent()}
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>
    </div>
  );
}

export default DesignContent;
