import React, { useRef, useState } from "react";

import PageLayout from "components/common/PageLayout";
import InfoBoxesList, { InfoBoxType } from "components/common/InfoBoxesList";
import translator from "utils/translator";
import css from "./EditorPage.module.scss";
import images from "assets/images";

type Props = {};

const _t = (path: string) => translator.text(`editorPage.${path}`);

const infoItems: Array<Array<InfoBoxType>> = [
  [
    {
      title: _t("infoItems.designItTitle"),
      text: _t("infoItems.designItText"),
      icon: "pensil",
    },
    {
      title: _t("infoItems.notMoreTitle"),
      text: _t("infoItems.notMoreText"),
      icon: "star",
    },
  ],
  [
    {
      title: _t("infoItems.designResponsiveTitle"),
      text: _t("infoItems.designResponsiveText"),
      icon: "responsive",
    },
    {
      title: _t("infoItems.fontsTitle"),
      text: _t("infoItems.fontsText"),
      icon: "font",
    },
  ],
  [
    {
      title: _t("infoItems.contentTitle"),
      text: _t("infoItems.contentText"),
      icon: "plus",
    },
    {
      title: _t("infoItems.platformTitle"),
      text: _t("infoItems.platformText"),
      icon: "mobile",
    },
  ],
  [
    {
      title: _t("infoItems.createReusableTitle"),
      text: _t("infoItems.createReusableText"),
      icon: "symbol",
    },
    {
      title: _t("infoItems.gridTitle"),
      text: _t("infoItems.gridText"),
      icon: "grid",
    },
  ],
  [
    {
      title: _t("infoItems.globalStickyTitle"),
      text: _t("infoItems.globalStickyText"),
      icon: "layers",
    },
    {
      title: _t("infoItems.animationTitle"),
      text: _t("infoItems.animationText"),
      icon: "animation",
    },
  ],
  [
    {
      title: _t("infoItems.shareTitle"),
      text: _t("infoItems.shareText"),
      icon: "share",
    },
    {
      title: _t("infoItems.seoTitle"),
      text: _t("infoItems.seoText"),
      icon: "search",
    },
  ],
  [
    {
      title: _t("infoItems.userTitle"),
      text: _t("infoItems.userText"),
      icon: "user",
    },
    {
      title: _t("infoItems.ownCodeTitle"),
      text: _t("infoItems.ownCodeText"),
      icon: "embed",
    },
  ],
  [
    {
      title: _t("infoItems.marketingTitle"),
      text: _t("infoItems.marketingText"),
      icon: "",
    },
    {
      title: _t("infoItems.revisionTitle"),
      text: _t("infoItems.revisionText"),
      icon: "",
    },
  ],
];

const EditorPage: React.FC<Props> = () => {
  const [videoPlaying, setVideoPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(document.createElement("video"));
  const videoUrl = `https://player.vimeo.com/external/567035801.hd.mp4?s=28f9becd34e9233f30f79b702ac62cf90990bcb7&profile_id=175`;
  return (
    <PageLayout className={css.editorPage}>
      <h1 className={css.pageTitle}>{_t("title")}</h1>

      <div className={`${css.videoWrap} cursor-pointer relative`}>
        <img
          src={images.meetStoryCardsVideo}
          className={`${
            css.overlayImage
          } pointer-events-none absolute w-full z-10 duration-300 ${
            videoPlaying && "invisible opacity-0"
          }`}
          alt=""
        />
        <video
          onClick={() => {
            setVideoPlaying(true);
            if (videoRef) {
              // videoRef.current.click();
            }
          }}
          ref={videoRef}
          className={`w-full `}
          src={videoUrl}
          controls
        />
      </div>

      <InfoBoxesList items={infoItems} className={css.info} />
    </PageLayout>
  );
};

export default EditorPage;
