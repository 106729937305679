import Footer from 'components/common/Footer';
import Header from 'components/common/Header';
import React from 'react';
import { useLocation } from 'react-router-dom';
import styles from './styles.module.scss';
import _ from 'lodash';

import { NEW_SIGN_UP } from 'components/constants';
import translator from 'utils/translator';
import icons from 'assets/icons';
import useResponsiveQuery from 'utils/useResponsiveQuery';

type planType = {
  name: string;
  price: string;
  billing: string;
  cta: string;
  views: string;
  features: string[];
};

type warrantyType = {
  title: string;
  description: string | null;
};

function PricingPage() {
  const _t = (path: string) => translator.text(`pricing.${path}`);
  const plans: planType[] = _t('plans');
  const warranty: warrantyType[] = _t('warranty');
  const isMobile = useResponsiveQuery();
  const { search } = useLocation();
  const searchWithQuestion = search.split('?')[1];

  return (
    <>
      <Header isPricingPage />
      <div className={`${styles.pricingPage} flex flex-col items-center w-screen`}>
        <h1 className={`${styles.title} text-center font-semibold`}>{_t('title')}</h1>
        <div className={`${styles.table} flex flex-col lg:flex-row border-black`}>
          {plans.map((plan, index) => (
            <div key={plan.name} className={`${styles.column} ${index > 0 && styles.columnWithBorder}`}>
              <div className={`${styles.topSection} `}>
                <h2 className={`${styles.planTitle} font-semibold`}>{plan.name}</h2>
                <p className={`${styles.price} font-semibold`}>{plan.price}</p>
                {isMobile ? (
                  plan.billing && <h3 className={`${styles.billing} `}>{plan.billing}</h3>
                ) : (
                  <h3 className={`${styles.billing} `}>{plan.billing}</h3>
                )}

                <a href={`${NEW_SIGN_UP}?plan=${plan.name}${searchWithQuestion ? `&${searchWithQuestion}` : ''}`}>
                  <button
                    className={`${styles.cta} ${_.get(
                      styles,
                      `cta${index}`,
                    )} text-white font-semibold text-center rounded-full`}
                  >
                    {plan.cta}
                  </button>
                </a>
              </div>
              <div className={`${styles.bottomSection} `}>
                <h4 className={`${styles.views} font-semibold`}>{plan.views}</h4>
                <ul className={`${styles.features} `}>
                  {plan.features.map((item) => (
                    <li key={item} className={`${styles.featureItem} `}>
                      <div dangerouslySetInnerHTML={{ __html: item }}></div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
        <div className={`${styles.warranty} w-full flex flex-col lg:flex-row justify-between`}>
          {warranty.map((item, index) => (
            <div className={`lg:flex-1`} key={item.title}>
              <div className={`${styles.iconWrapper} flex items-center`}>
                <img className={`${_.get(styles, `warrantyIcon${index}`)}`} src={warrantyIcons[index]} alt="" />
              </div>

              <h5 className={`${styles.warrantyTitle} font-semibold`}>{item.title}</h5>
              {item.description ? (
                <p className={`${styles.warrantyDescription} `}>{item.description}</p>
              ) : (
                <img className={`${styles.payments} `} src={icons.payments} alt="" />
              )}
            </div>
          ))}
        </div>
      </div>
      <Footer isPricingPage />
    </>
  );
}

export default PricingPage;

const warrantyIcons = [icons.creditcardIcon, icons.moneyBackIcon, icons.lockIcon];
