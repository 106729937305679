import React from "react";
import _ from "lodash";
import classNames from "classnames";

import useResponsiveQuery from "utils/useResponsiveQuery";

import InfoBox from "../InfoBox";
import Icon from "../Icon";

import css from "./InfoBoxesList.module.scss";

interface Props {
  className?: string;
  items: InfoBoxType[][];
}

export type InfoBoxType = {
  title?: string;
  text: string;
  icon?: string;
};

const InfoBoxesList: React.FC<Props> = ({
  items,
  className = "",
}: React.ComponentProps<typeof InfoBoxesList>) => {
  const isMobile = useResponsiveQuery();

  return (
    <div className={classNames(css.info, className)}>
      {_.map(items, (item, index) => (
        <div key={`info-row-${index}`} className={css.infoRow}>
          {_.map(
            item,
            (box: InfoBoxType, boxIndex: number, array: InfoBoxType[]) =>
              _.size(array) === 1 ? (
                <React.Fragment key={`info-item-${boxIndex}`}>
                  <InfoBox
                    key={`info-item-${boxIndex}`}
                    className={css.infoItem}
                    text={box.text}
                    title={box.title}
                    renderIcon={
                      box.icon
                        ? () => (
                            <Icon
                              type={box.icon as string}
                              width={isMobile ? 40 : 80}
                            />
                          )
                        : null
                    }
                  />
                  <div className={classNames(css.infoItem, css.empty)} />
                </React.Fragment>
              ) : (
                <InfoBox
                  key={`info-item-${boxIndex}`}
                  className={css.infoItem}
                  text={box.text}
                  title={box.title}
                  renderIcon={
                    box.icon
                      ? () => (
                          <Icon
                            type={box.icon as string}
                            width={isMobile ? 40 : 80}
                          />
                        )
                      : null
                  }
                />
              )
          )}
        </div>
      ))}
    </div>
  );
};

export default InfoBoxesList;
